.repay-action-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.repay-arrow-separator {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 50px;
  background-color: #06071D;
  align-items: center;
}

.transform-token-arrow {
  color: #E65769;
}

.token-selector-short-wrapper {
  width: 100%;
}

.token-selector-short-wrapper .token-symbol {
  margin: 0 10px;
}

.token-selector-short-wrapper .token-amount-entry {
  padding-left: 10px;
}

@media screen and (max-width: 600px) {
  .repay-action-container .row-title:nth-child(2){
    margin-top: 10px;
  }

  .repay-action-container {
    padding-top: 5px;
  }
}