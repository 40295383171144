html, body, #root, .App, #app, #app > div {
  height: 100%;
}

html, body {
  background-color: #f4f4f4;
}
html.dark-mode, body.dark-mode {
  background-color: #050409;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  background: url("./img/bgs/bg-light.jpg") no-repeat;
  background-size: 100% 100%;
}
.App.dark-mode {
  background: url("./img/bgs/bg-dark.jpg") no-repeat;
  background-size: 100% 100%;
}

.top-level-button {
  position: absolute;
  top: 10px;
  height: 40px;
  width: auto;
  padding: 0 1em;
  border-radius: 6px;
  color: #AEAFB7;
  background-color: #270E4C;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all ease 0.3s;
  cursor: pointer;
}

a, a:hover, a:visited, a:active {
  outline: none;
  color: inherit;
  text-decoration: none;
}

.create-pool-button {
  left: 10px;
}

.top-level-button:hover {
  filter: brightness(80%);
}

.back-blob {
  width: 750px;
  height: 750px;
  position: absolute;
  z-index: 0;
}

.view-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 17px;
  height: auto;
  background-color: var(--white);
  border-radius: 6px;
  box-shadow: 0px 1px 10px rgba(59, 58, 67, 0.08);
}

.flexbox-container {
  display: flex;
  flex-direction: column;
  padding: 1em 0;
  border-radius: 6px;
  background: rgba(0, 0, 0, 0);
  align-items: center;
  justify-content: space-evenly;
  transition: all ease 0.3s;
}

.flexbox-container.no-padding {
  padding: 0;
}

.token-input-container {
  width: 100%;
  height: 150px;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.blob-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
}

.bring-front {
  z-index: 2;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.main-container-shadow {
  box-shadow: 0px 1px 10px rgba(59, 58, 67, 0.08);
}

.no-padding {
  padding: 0;
}

.__react_component_tooltip {
  background-color: var(--white) !important;
  color: rgb(52, 64, 84) !important;
  max-width: 320px;
  border-radius: 8px;
  text-align: left;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  font-family: "Inter", sans-serif;
  font-weight: 600;
  opacity: 1 !important;
}

.__react_component_tooltip.dark-mode {
  color: white !important;
}

.__react_component_tooltip::after {
  border-top-color: var(--white) !important;
}
/*# sourceMappingURL=App.css.map */