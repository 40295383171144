.graph-tabs-wrapper {
  background-color: var(--white);
  border-radius: 7px;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  height: 303px;
  padding: 16px;
  position: relative;
}

.graph-tabs-wrapper .graph-tabs {
  display: flex;
  position: relative;
  margin-bottom: 20px;
}

.graph-tabs-wrapper .graph-tabs:after {
  content: "";
  display: block;
  position: absolute;
  left: 1em;
  bottom: 13px;
  width: calc(100% - 2em);
  height: 1px;
  background: hsl(218, 15%, 85%);
  z-index: 1;
}

.graph-tabs-wrapper .graph-tabs .graph-tab {
  cursor: pointer;
  margin-right: 28px;
  position: relative;
  color: hsl(227, 8%, 65%);
  font-family: "Sora", sans-serif;
  transition: all ease 0.3s;
}

.graph-tabs-wrapper .graph-tabs .graph-tab.active {
  color: black;
}

.graph-tabs-wrapper .graph-tabs .graph-tab.active:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: -3px;
  width: 100%;
  height: 2px;
  background: #000;
  border-radius: 4px;
  z-index: 2;
}

.graph-tabs-wrapper .simulation-wrapper {
  box-shadow: none;
  background: none;
}

.graph-tabs-wrapper .simulation-title {
  display: none;
  margin: 0;
}

.graph-tabs-wrapper .active-graph > div {
  padding: 0;
  padding-bottom: 20px !important;
  height: 500px;
  max-height: 260px;
}

.price-estimate-warning {
  position: absolute;
  bottom: 43px;
  left: 65px;
  font-size: 14px;
  color: var(--manatee);
  display: flex;
  align-items: center;
  z-index: 10;
}

.price-estimate-warning svg {
  margin-right: 8px;
  width: 15px;
  color: var(--cherry);
  transform: translateY(-1px);
}

@media screen and (max-width: 1183px) {
  .graph-tabs-wrapper {
    margin-bottom: 16px;
    height: 320px;
  }
}