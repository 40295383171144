.asset-row-wrapper {
  width: 100%;
  height: auto;
  min-height: 55px;
  background: var(--white);
  border-radius: 6px;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  margin-bottom: 10px;
  padding: 0;
  padding-top: 15px;
  flex-direction: column;
  align-items: center;
  transition: all ease 0.3s;
  border: 1px solid white;
}

.asset-row-wrapper:hover {
  box-shadow: 0px 10px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
}

.asset-row-header {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  padding: 0 16px;
  font-family: "Sora", sans-serif;
}

.asset-row-header .asset-row-icons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.asset-row-header .asset-row-icons img {
  width: 24px;
  height: 24px;
  border-radius: 100%;
}

.asset-row-header .asset-row-icons img:last-child {
  transform: translateX(-15px);
}

.asset-row-header .asset-row-description {
  font-size: 16px;
  margin-right: 15px;
  font-weight: 500;
  color: var(--black);
  display: flex;
  height: 21px;
}

.asset-row-header .asset-price {
  color: var(--text-grey);
  font-weight: normal;
  margin: 0 4px;
}

.asset-row-header-right-section {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 16px;
}

.asset-row-chevron {
  margin-left: 12px;
  color: var(--cta-blue);
  width: 20px;
  height: 20px;
}

.asset-row-header .asset-row-pool-count {
  background: var(--tag-blue);
  border-radius: 16px;
  padding: 3px 11px;
  font-size: 11px;
  color: var(--cta-blue);
  display: flex;
  justify-content: center;
  font-weight: 500;
  width: 59px;
  height: 22px;
  white-space: nowrap;
  right: 16px;
}

.asset-row-pool-count .MuiSkeleton-root {
  width: 100%;
}

.asset-row-header .asset-row-toggle {
  color: var(--cta-blue);
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  font-size: 13px;
}

.asset-row-header .asset-row-toggle svg {
  width: 20px;
  height: 20px;
  margin-left: 0.5em;
}

.asset-row-wrapper .pool-rows-container {
  width: 100%;
  background: var(--white);
  height: 0;
  padding: 0;
  overflow-y: hidden;
}

.asset-row-wrapper .pool-rows-container:last-child {
  border-bottom-left-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
  display: block;
}

.asset-row-wrapper .pool-rows-container.showPools {
  height: auto;
  padding-top: 24px;
  overflow-y: visible;
}

.pool-rows-header-container {
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
  margin-bottom: 11px;
  position: relative;
}

.my-pools-wrapper .pool-rows-header-container {
  padding: 0 20px;
}

.pool-rows-header-container::after {
  content: "";
  display: block;
  position: absolute;
  width: 97%;
  height: 1px;
  background: rgba(245,246,250,1);
  bottom: -11px;
  transform: translateX(-8px);
}

.pool-rows-header-container .pool-column-header {
  font-size: 13px;
  cursor: pointer;
  color: var(--text-grey);
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  text-align: left;
}

.pool-rows-header-container .pool-column-header span {
  margin-right: 5px;
}

.pool-rows-header-container .pool-column-header svg {
  width: 10px;
  height: 10px;
  fill: var(--text-grey);
}

.asset-row-description .MuiSkeleton-root {
  width: 62px;
  display: inline;
  margin: 0 4px;
  margin-top: -2px;
}

@media screen and (max-width: 600px) {
  .asset-row-description {
    transform: translateX(-10px);
    font-size: 14px !important;
  }
  .asset-row-toggle-text {
    display: none;
  }
  .asset-row-pool-count {
    right: 6% !important;
  }
}
@media screen and (max-width: 475px) {
  .asset-row-pool-count {
    display: none !important;
  }
}/*# sourceMappingURL=AssetRow.css.map */