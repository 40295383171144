.token-selector-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 43px;
  background-color: var(--background);
  border-radius: 6px;
  align-items: center;
  justify-content: space-between;
  transition: all ease 0.3s;
  position: relative;
}

.token-selector-container .MuiSkeleton-root {
  min-width: 80px;
  max-height: 15px;
  transform: translateX(-10px);
}

.token-selector-container.select:hover {
  filter: brightness(90%);
}


.token-selector-container.select {
  cursor: pointer;
}


.token-selector-container.select svg {
  color: var(--cta-blue);
  cursor: pointer;
  height: 24px;
  min-width: 30px;
  min-height: 24px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.token-selector-container.select input {
  cursor: pointer;
  color: #9ea1ac;
  max-width: 100px;
  overflow-x: visible;
  transform: translateX(-30px);
  text-align: right;
}

.token-selector-separator {
  height: 45px;
  width: 1px;
  background-color: #DEE1ED;
  position: absolute;
  right: 0;
}

.token-symbol {
  color: var(--dark-jungle-green);
  font-weight: 500;
  white-space: nowrap;
}

.token-drop-down .token-logo {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.token-amount-entry {
  background-color: transparent;
  border: none;
  height: 45px;
  padding-left: 0.5em;
  outline: none;
  color: var(--black);
  font-size: 14px;
  width: inherit;
  display: flex;
  align-items: center;
}

.token-selector-container.select input {
  cursor: pointer;
  color: #9ea1ac;
}

.token-selector-separator {
  height: 37px;
  width: 1px;
  background-color: var(--line);
  position: absolute;
  right: 0;
}

.token-balance {
  color: var(--text-light-grey);
  justify-self: flex-end;
  margin-right: 10px;
  font-size: 0.9em;
  white-space: nowrap;
  cursor: pointer;
  font-size: 12px;
}

.token-logo {
  width: 35px;
  height: 35px;
}

.token-symbol {
  color: var(--black);
  font-weight: 500;
}

.token-drop-down {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 0 10px;
  align-items: center;
}

.explore-wrapper .token-drop-down .token-symbol {
  max-width: 80px;
  overflow-x: hidden;
  display: block;
}

.my-pools-wrapper .token-drop-down .token-symbol {
  max-width: 80px;
  overflow-x: hidden;
  display: block;
}

.create-pool-page-wrapper .token-drop-down .token-symbol {
  max-width: 120px;
  overflow-x: hidden;
  display: block;
}