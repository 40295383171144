.lending-pool-wrapper {
  width: 100%;
  height: 100%;
  border-radius: 6px 6px 0 0;
}

.info-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.info-container input {
  width: 185px;
}

.row-title {
  align-self: flex-start;
  justify-self: left;
  color: var(--text-grey);
  font-size: 12px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 7px;
  align-items: center;
}

.row-title-label {
  display: flex;
  align-items: center;
}

.row-title-token-link {
  height: 10px;
  width: 10px;
  margin-left: 6px;
  transform: translateY(1px);
  cursor: pointer;
  outline: none;
}

.row-title-token-link:hover {
  color: var(--cta-blue);
}

.info-section-container .info-title {
  margin-bottom: 3px;
}

.term-fee-title {
  transform: translateX(-3px);
}

.annualized-rate-tip {
  min-width: 110px;
  white-space: nowrap;
}

.term-fee-title .tool-tip {
  min-width: 320px;
}

.info-title-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 40px;
}

.pool-link {
  font-size: 13px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 3px;
  margin-right: 5px;
  color: var(--cta-blue) !important;
}

.pool-link svg {
  width: 10px;
  margin-left: 5px;
  transform: translateY(-1px);
}

.input-price {
  opacity: 0.7;
  font-size: 12px;
}

.info-title {
  color: var(--text-grey);
  display: flex;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.info-title.expiry-title {
  min-width: 185px;
}

.info-title svg {
  width: 13.6px;
  margin-left: 7px;
  color: var(--cta-blue);
}

.borrow-view {
  align-items: center;
  justify-content: space-evenly;
  height: auto;
  margin-bottom: -7px;
  border-radius: 6px;
}

.repay-view {
  align-items: center;
  justify-content: flex-start;
}

.repay-view.view-section {
  padding-top: 10px;
  padding-bottom: 10px;
}

.lending-pool-activity-wrapper {
  max-width: 487px;
  position: relative;
  background-color: var(--white);
  border-radius: 6px;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
}

.lending-pool-activity-wrapper .line-tabs-wrapper {
  width: 91%;
  margin: auto;
  padding-top: 16px;
}

.borrow-view, .repay-view {
  padding: 16px;
}

.repay-view {
  padding-top: 0;
}

.lending-pool-activity-wrapper .line-tabs-wrapper {
  margin-bottom: 18px;
}

.lending-pool-activity-wrapper .line-tab {
  width: 50%;
  text-align: center;
  margin: 0;
  font-size: 16px;
}

.repay-view .row-header span:nth-child(2) {
  flex: 2;
}

.rollover-view {
  align-items: center;
  justify-content: flex-start;
  height: 250px;
  position: relative;
}

.rollover-view.view-section {
  padding-top: 10px;
  padding-bottom: 10px;
}

.rollover-pool-notice {
  color: var(--black);
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.row-header {
  display: flex;
  width: 100%;
  margin: auto;
  justify-content: space-around;
  margin-top: 18px;
  margin-bottom: 8px;
}

.row-header span {
  flex: 1;
  display: flex;
  justify-content: center;
  color: #9ea1ac;
  font-size: 12px;
}

.lending-pool-wrapper {
  height: 202px;
}

.transaction-alert {
  margin-top: 15px;
}

.explore-right-section {
  max-width: 368px;
}

.explore-right-section .flexbox-container {
  background: var(--white);
}

.explore-right-section .pool-link {
  display: none;
}

.borrow-view .info-section-container {
  display: flex;
  width: 100%;
  height: auto;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}
.borrow-view .info-section-container .loan-detail {
  height: 72px;
}
.borrow-view .info-section-container .loan-detail:first-child {
  width: 165px;
}
.borrow-view .info-section-container .loan-detail:nth-child(3) {
  transform: translateX(-5px);
}
.borrow-view .info-section-container .loan-detail .selector-container {
  margin-right: 0;
}
.borrow-view .info-section-container.block {
  display: block;
}

.lending-pool-wrapper .selector-container {
  width: 150px;
}

.token-selector-long-wrapper {
  width: 100%;
  margin-top: 3px;
}

.row-title-row {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin-top: 10px;
  padding-left: 9px;
  font-size: 14px;
}

.repay-title {
  color: #B8B8BA;
}

.top-offset {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.repay-title .row-header .rollover-pool-notice {
  font-size: 14px;
}

.lending-pool-close-modal-wrapper {
  color: var(--cta-blue);
  z-index: 10;
  background-color: var(--white);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  box-shadow: 0px 1px 10px rgba(59, 58, 67, 0.1);
  padding: 3px;
}

.lending-pool-close-modal {
  width: 20px;
  height: 20px;
}

@media screen and (max-width: 1180px) {
  .lending-pool-wrapper {
    height: auto;
  }
  .transaction-review {
    max-width: 487px;
  }
}
@media screen and (max-width: 600px) {
  .lending-pool-activity-wrapper, .transaction-review {
    max-width: 95% !important;
    min-width: 95% !important;
    transform: translateX(-2px);
  }
  .my-pools-right-section .lending-pool-activity-wrapper {
    padding-bottom: 20px;
  }
  .token-amount-entry {
    font-size: 12pt;
  }
  .repay-row, .row-header {
    display: none !important;
  }
  .repay-view {
    max-height: 220px;
    height: auto;
  }
  .pool-link {
    display: none;
  }
  .rollover-view {
    height: 200px;
  }
  .rollover-pool-notice {
    white-space: nowrap;
  }
  .info-title.expiry-title {
    min-width: 40%;
  }
  .info-title-container {
    margin-top: 35px;
  }
  .info-value.ltv-value,
  .info-title.ltv-title {
    transform: translateX(10px);
  }
  .info-value.term-fee-value,
  .info-title.term-fee-title {
    transform: translateX(15px);
  }
  .review-header {
    flex-direction: column;
    align-items: flex-start;
  }
}/*# sourceMappingURL=LendingPool.css.map */