.rollover-row {
  position: relative;
  display: flex;
  width: 100%;
  height: 45px;
  align-items: center;
  justify-content: space-around;
  transition: all ease 0.3s;
  cursor: pointer;
  font-size: 12px;
  background: var(--white);
  position: relative;
}

.rollover-row:before {
  content: "";
  position: absolute;
  width: 90%;
  height: 1px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background:#F5F6FA
}

.rollover-row.active {
  background: var(--selected-blue);
}

.rollover-expiry {
  white-space: pre;
}

.rollover-row div {
  width: 200px;
  display: flex;
  justify-content: center;
}

.rollover-icon-pair {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rollover-chevron-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60px;
}

.rollover-row-red {
  color: #F85062;
}

.rollover-pair {
  margin-left: 5px;
}

.rollover-text {
  font-size: 14px;
  color: var(--dark-jungle-green);
}

.scroll-view{
  width: 100%;
  height: 80%;
  display: block;
  align-items: center;
  margin-top: 5px;  
  overflow-x: hidden;
  overflow-y: auto;
}

.token-logo {
  width: 25px;
  height: 25px;
  border-radius: 100%;
}