.private-borrower-wrapper {
  color: var(--white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--cta-blue);
  border-radius: 6px;
  height: 30px;
  margin: 5px 6px 5px 0;
  padding: 0 6px;
  font-size: 10pt;
  color: var(--cta-blue);
}
.private-borrower-wrapper .private-borrower-remove {
  width: 15px;
  color: var(--red-accent);
  cursor: pointer;
  margin-left: 10px;
  transform: translateY(-1px);
}/*# sourceMappingURL=PrivateBorrower.css.map */