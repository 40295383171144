.reactions-wrapper {
  background: var(--white);
  padding: 22px;
  width: 400px;
  border-radius: 6px;
  outline: none;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}
.reactions-wrapper .reactions-header span {
  width: 100%;
  display: block;
}
.reactions-wrapper .reactions-header .header-title {
  font-size: 25px;
  color: var(--black);
  margin-bottom: 11px;
}
.reactions-wrapper .reactions-header .header-description {
  font-size: 14px;
  color: var(--text-light-grey);
}
.reactions-wrapper .reaction-option {
  width: 100%;
  display: flex;
  height: 45px;
  border-radius: 6px;
  align-items: center;
  margin: 16px 0;
  cursor: pointer;
  padding: 0 16px;
  transition: all ease 0.3s;
  /* White */
  background: #FFFFFF;
  /* Gray/300 */
  border: 1px solid #D0D5DD;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}
.reactions-wrapper .reaction-option svg {
  margin-right: 11px;
  color: black;
  width: 16px;
  height: 16px;
  transform: translateY(2px);
}
.reactions-wrapper .reaction-option .reaction-option-text {
  color: var(--black);
}
.reactions-wrapper .reaction-option:hover {
  background: var(--hover-grey) !important;
}
.reactions-wrapper .reaction-option.selected {
  background: var(--selected-blue) !important;
}
.reactions-wrapper .reaction-option.selected .reaction-option-text {
  color: black;
}/*# sourceMappingURL=Reactions.css.map */