.settings-manager-wrapper {
  background: var(--white);
  border-radius: 6px;
  min-height: 80px;
  padding: 5px 16px;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  margin-top: 16px;
}

.settings-manager-wrapper.active {
  padding: 16px;
}

.settings-manager-tabs {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.settings-manager-tab {
  display: flex;
  flex-direction: column;
  position: relative;
  height: auto;
  transition: all ease 0.3s;
  padding-bottom: 0px;
}

.settings-manager-tab:not(.open) {
  overflow: hidden;
}

.settings-manager-header {
  height: 46px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: 'Sora', sans-serif;
}

.settings-manager-tab .settings-tab-icon {
  margin-right: 19px;
}

.settings-manager-tab .settings-tab-svg {
  position: absolute;
  transition: all ease 0.3s;
  right: 0;
  top: 11px;
  margin: 0;
  padding: 0;
  width: 20px;
  height: 20px;
  color: var(--line);
}
.settings-manager-tab .settings-tab-svg.open {
  transform: rotate(90deg);
}

.settings-manager-tab:not(:last-child):after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 1px;
  background: rgb(245, 246, 250);
  transform: translateY(0px);
}

.settings-manager-active-tab {
  height: auto;
  padding: 0;
  max-height: 0;
  transition: all ease 0.3s;
  opacity: 0;
}
.settings-manager-active-tab.open {
  padding-top: 5px;
  max-height: 300px;
  opacity: 1;
}

.settings-manager-tab.open {
  padding-bottom: 16px;
}

.settings-manager-tab:last-child.open {
  padding-bottom: 10px;
}

.settings-tab-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 168px;
  height: 100%;
}

.settings-tab-button-wrapper button {
  height: 40px;
}

.settings-tab-button-wrapper.set-rollover .action-button-container {
  background: var(--cta-blue);
}

.settings-tab-button-wrapper.submit-featured-pool .action-button-container {
  background: var(--cta-blue);
}

.settings-tab-button-wrapper.update-fee-rate .action-button-container {
  background: var(--cta-blue);
}

.settings-tab-button-wrapper.pause-borrowing .action-button-container {
  background: var(--cta-red);
}

.settings-tab-button-wrapper .action-button-container:disabled {
  filter: brightness(70%);
}

.update-fee-rate-slider-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.update-fee-rate-slider-input-wrapper {
  display: flex;
  justify-content: flex-end;
  position: relative;
  height: 35px;
}

.update-fee-rate-slider-input-wrapper input {
  position: absolute;
  right: 0;
  top: -25px;
  width: 52px;
  height: 35px;
  border-radius: 6px;
  background-color: var(--background);
  border: none;
  text-align: right;
  padding-right: 23px;
  font-size: 12px;
}

.update-fee-rate-slider-input-wrapper span {
  position: absolute;
  right: 7px;
  top: -8px;
  transform: translate(0, -50%);
  font-size: 12px;
}

.small-widget .widget-description {
  display: block;
  font-size: 12px;
  color: var(--text-grey);
  margin-bottom: 16px;
}

.update-fee-rate-wrapper .widget-description {
  margin-bottom: 5px;
}/*# sourceMappingURL=SettingsManager.css.map */