.action-button {
  width: 100%;
  transition: all ease 0.1s;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  border-radius: 8px;
  font-weight: 600;
  border: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  margin: auto;
  font-size: 16px;
  text-transform: capitalize;
}

.action-button-container {
  color: #fff;
  background: #8d4ad4;
  background: linear-gradient(94.37deg, #F4A352 1.85%, #F48C60 33.11%, #276DDF 98.67%);
}

.secondary-action-button-container {
  color: var(--black);
  background: var(--white);
  border: 1px solid #D0D5DD;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-weight: 600;
}

.secondary-action-button-container:hover:not(:disabled) {
  background: #F9FAFB !important;
  border: 1px solid #D0D5DD !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
}

.secondary-action-button-container:active:not(:disabled) {
  background: var(--white) !important;
  border: 1px solid #D0D5DD !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F2F4F7 !important;
}

.action-button-container:hover:not(:disabled) {
  background: linear-gradient(94.45deg, #E3913F 1.73%, #E0794E 34.01%, #024AC5 98.57%);
}

.action-button-container:active:not(:disabled) {
  background: linear-gradient(94.7deg, #F4A352 1.35%, #F38C60 33.65%, #175CD3 98.26%);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #DCECFF;
}

.action-button-container:disabled {
  cursor: not-allowed;
  background: linear-gradient(94.45deg, rgba(244, 163, 82, 0.35) 1.73%, rgba(243, 140, 96, 0.35) 34.01%, rgba(23, 92, 211, 0.35) 98.57%);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: white;
}

.secondary-action-button-container:disabled {
  background: var(--white);
  border: 1px solid #EAECF0;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}
