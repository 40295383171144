.line-tabs-wrapper {
  display: flex;
  width: 100%;
  position: relative;
  font-family: "Inter", sans-serif;
}
.line-tabs-wrapper::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: -7px;
  width: 100%;
  height: 1px;
  background: var(--line);
  z-index: 1;
}
.line-tabs-underline {
  position: absolute;
  left: 0;
  bottom: -8px;
  height: 2px;
  background: white;
  transition: all ease 0.3s;
  z-index: 10;
}
.line-tab {
  cursor: pointer;
  margin-right: 28px;
  position: relative;
  color: var(--text-light-grey);
  transition: all ease 0.3s;
}
.line-tab::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: -7px;
  width: 0;
  height: 2px;
  background: var(--line);
  z-index: 2;
  transition: all ease 0.3s;
}
.tab-title-wrapper {
  display: flex;
}
.tab-title-wrapper .tab-value {
  display: block;
  max-width: 17px;
  white-space: nowrap;
}
.tab-title-wrapper .tab-name {
  margin-right: 8px;
  transition: all ease 0.3s;
}
.line-tab.active {
  color: var(--black) !important;
}
.tab-title-wrapper .tab-name.active {
  font-weight: 600;
}
.explore-wrapper .line-tab span {
  color: white;
}
.explore-wrapper .line-tab.active span {
  color: white !important;
}
.my-pools-wrapper .line-tabs-underline {
  background: var(--black);
}
.create-pool-wrapper .line-tabs-underline{
  background: var(--black);
}
.nav-link-container .line-tabs-wrapper::after {
  display: none;
}
.nav-link-container .line-tab {
  color: white !important;
  font-size: 16px;
  margin-right: 2em;
  font-family: "Sora", sans-serif;
}
.nav-link-container .line-tab.active {
  font-weight: 700;
}
.nav-link-container .line-tabs-underline {
  bottom: -24px;
}
