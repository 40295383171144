.my-pools-wrapper {
  width: 100%;
  height: 100%;
  padding-bottom: 5px;
  display: flex;
}

.my-pools-wrapper .my-pools-left-section {
  flex: 1.5;
  margin-right: 15px;
}

.v1-message {
  margin-top: 10px;
  position: relative;
}

.v1-message u {
  cursor: pointer;
}

.v1-message .MuiAlert-message button {
  border: none;
  background: none;
  cursor: pointer;
  color: rgb(13, 60, 97);
}

.v1-message a {
  text-decoration: underline;
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  color: rgb(13, 60, 97);
  font-size: 13px;
}

.my-pools-wrapper .my-pools-right-section {
  flex: 1;
}

.my-pools-wrapper .my-pools-header {
  margin-top: 31px;
  margin-bottom: 16px;
  display: flex;
}

.my-pools-wrapper .loader {
  position: absolute !important;
  top: 56%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.my-pools-graph-section {
  height: 254px;
  display: flex;
}

.my-pools-graph-section .my-pools-left-graph {
  flex: 1.5;
  margin-right: 11px;
  height: 100%;
  min-width: 498px;
  min-height: 219px;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  border-radius: 6px;
}

.my-pools-graph-section .my-pools-right-graph {
  flex: 1;
  max-width: 297px;
}

.my-pools-right-graph .no-data-message {
  position: absolute;
  top: 46%;
  left: 50%;
  width: 80px;
  text-align: center;
  font-size: 12px;
  transform: translateX(-50%);
  color: var(--auro-metal-saurus);
  z-index: 1;
}

.my-pools-graph-section .graph-container {
  height: 100%;
  border-radius: 6px;
  position: relative;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  padding: 15px;
  background: var(--white);
  font-size: 10px;
  min-height: 219px;
}

.my-pools-wrapper .pool-rows-container {
  width: 100%;
  background: var(--white);
  overflow: visible;
  padding-top: 16px;
  border-radius: 0px 0px 6px 6px;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
}

.pool-row-header {
  width: 100%;
  display: flex;
  margin-bottom: 0.5em;
  justify-content: space-between;
  font-size: 14px;
  flex: 1;
}

.pool-row-header-entry {
  font-size: 16px;
  color: var(--almost-white);
  text-align: center;
  width: 120px;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}

.pool-row-header-entry.sortable {
  cursor: pointer;
}

.pool-row-header-entry.sortable svg {
  margin-left: 3px;
  max-width: 15px;
  max-height: 15px;
  min-width: 15px;
  min-height: 15px;
}

.pool-row-header-entry.sortable.ascending svg {
  transform: rotate(180deg);
}

.pool-rows-wrapper {
  width: auto;
  overflow-y: auto;
  flex: 1;
}

.pool-rows-wrapper .no-pools-notice {
  position: relative;
  display: flex;
  width: 100%;
  height: 60%;
  justify-content: center;
  align-items: center;
}

.my-pools-wrapper .tab-container {
  background: none;
  width: 100%;
}

.my-pools-wrapper .tab-container .tab-slider {
  width: 50%;
}

.my-pools-wrapper .tab-slider.lent {
  transform: translateX(100%);
}

.my-pools-wrapper .tab-slider.repay, .my-pools-wrapper .tab-slider.add {
  left: 0;
}
.my-pools-graph-section {
  height: 254px;
  display: flex;
}
.my-pools-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 35px;
}

.balance-manager-wrapper .line-tabs-wrapper {
  margin-bottom: 6px;
}

.balance-manager-wrapper .line-tab {
  width: 50%;
  text-align: center;
  margin: 0;
  font-size: 16px;
}

.my-pools-line-tab-wrapper {
  background: var(--white);
  padding: 15px 15px 5px 15px;
  border-radius: 6px 6px 0 0;
}

.mode-switch-container {
  width: 350px;
  height: 40px;
  background: var(--white);
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  border-radius: 6px;
  display: flex;
  padding: 4px;
}
.mode-switch-container .mode-switch-button {
  font-size: 14px;
  font-family: "Inter", sans-serif;
  border: none;
  width: 50%;
  background: none;
  color: var(--text-grey);
  border-radius: 4px;
  cursor: pointer;
  transition: all ease 0.3s;
}
.mode-switch-container .mode-switch-button.active {
  color: var(--cta-blue);
  background: var(--selected-blue);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}

.balance-manager-wrapper, .my-pools-collect-wrapper {
  width: 100%;
  height: auto;
  background: var(--white);
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  border-radius: 6px;
  padding: 16px;
  display: flex;
  flex-direction: column;
}

.balance-manager-wrapper .token-selector-container {
  margin-top: 15px;
}

.balance-manager-wrapper .toggle-wrapper {
  margin-top: 8px;
}

.my-pools-collect-wrapper .MuiSkeleton-root {
  display: none;
}

.my-pools-graph-title {
  font-size: 16px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  position: relative;
  color: var(--black);
}

.my-pools-graph-title::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -8px;
  width: 100%;
  height: 1px;
  background: rgb(224, 224, 231);
}

.pool-stats-button {
  background: var(--selected-blue);
  color: var(--cta-blue);
  width: 21px;
  height: 21px;
  border-radius: 100%;
  cursor: pointer;
  transition: all ease 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pool-stats-button svg {
  width: 14px;
  height: 14px;
  color: black;
}

.pool-stats-button:hover {
  box-shadow: 0px 0px 10px rgba(59, 58, 67, 0.3);
}

.pool-stats-button:active {
  transform: scale(1);
}

.balance-manager-wrapper .action-button-container, .my-pools-collect-wrapper .action-button-container {
  height: 45px;
  width: 50%;
  margin: 0;
  margin-top: 15px;
}

.my-pools-small-widgets-wrapper {
  display: flex;
  width: 100%;
  margin-top: 15px;
  justify-content: space-between;
}

.my-pools-small-widgets-wrapper .action-button-container {
  width: 100%;
  box-shadow: 0px 1px 10px rgba(59, 58, 67, 0.1);
}

.my-pools-small-widgets-wrapper .small-widget {
  width: 235px;
  padding: 23px;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  background: var(--white);
}

.my-pools-small-widgets-wrapper .small-widget .widget-icon {
  transition: all ease 0.3s;
  margin-bottom: 25px;
  width: 38px;
  height: 38px;
  min-width: 38px;
  min-height: 38px;
}

.my-pools-small-widgets-wrapper .small-widget .widget-icon:hover {
  transform: rotate(180deg);
}

.my-pools-small-widgets-wrapper .small-widget .action-button-container {
  min-height: 30px;
  max-height: 30px;
  font-size: 12px;
  border-radius: 7px;
}

.my-pools-small-widgets-wrapper .widget-header {
  font-weight: 600;
  font-size: 14px;
}

.my-pools-small-widgets-wrapper .widget-description {
  font-size: 12px;
  margin: 7px 0;
}

.my-pools-collect-wrapper .collect-inner-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.my-pools-collect-wrapper .collect-inner-wrapper .token-selector-container {
  width: 100%;
}

.my-pools-collect-wrapper .collect-inner-wrapper .token-selector-container:last-child {
  margin-top: 10px;
}

.my-pools-collect-wrapper .action-button-container {
  width: 100%;
}

.my-pools-collect-wrapper .collect-message {
  border-radius: 6px;
  margin-top: 13px;
}

.my-pools-left-graph .legend-item-3 {
  margin-right: 0;
}

.my-pools-graph-section .my-pools-right-graph .recharts-legend-wrapper {
  left: 68% !important;
  top: 36% !important;
  width: 100%;
}

.my-pools-graph-section .recharts-legend-item {
  margin-bottom: 4px;
}

.pie-chart-text {
  font-size: 24px;
  font-weight: 600;
  font-family: "Sora", sans-serif;
}

.my-pools-utilization-text {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--text-grey);
  font-weight: 400;
  font-size: 13px;
}

.utilization-value {
  font-family: "Sora";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  transform: translate(5px, 10px);
  color: var(--black);
}

.my-pools-right-section.modal {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;
  overflow-y: auto;
  padding: 0px 5px;
  padding-top: 40px;
}

.my-pools-close-modal {
  color: var(--cta-blue);
  z-index: 10;
  background-color: white;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  box-shadow: 0px 1px 10px rgba(59, 58, 67, 0.1);
  padding: 3px;
}

.my-pools-close-modal svg {
  width: 20px;
  height: 20px;
}

.rate-stats-wrapper {
  width: 100%;
  height: auto;
  transform: translateY(-10px);
}
.rate-stats-wrapper .rate-stat {
  display: flex;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
}
.rate-stats-wrapper .rate-stat:not(:last-child) {
  margin-bottom: 5px;
}
.rate-stats-wrapper .rate-stat .rate-stat-label {
  font-weight: 600;
}
.rate-stats-wrapper .rate-stat .rate-stat-value {
  color: var(--text-grey);
}

@media screen and (max-width: 1180px) {
  .my-pools-header {
    margin-top: 0 !important;
  }
  .my-pools-left-section {
    margin-right: 0 !important;
  }
  .balance-manager-wrapper, .my-pools-collect-wrapper {
    flex-direction: column;
    max-width: 326px;
  }
  .my-pools-graph-section {
    margin-bottom: 16px;
  }
}
@media screen and (max-width: 600px) {
  .my-pools-right-section {
    flex-direction: column;
  }
  .my-pools-left-section {
    max-width: 100% !important;
  }
  .my-pools-left-graph {
    margin-right: 0 !important;
    margin-bottom: 5px !important;
    width: 100%;
    min-width: 0 !important;
    max-width: 100% !important;
  }
  .my-pools-left-graph .pool-simulation-wrapper {
    margin-bottom: 0;
  }
  .my-pools-right-graph {
    width: 100%;
    max-width: 100% !important;
  }
  .my-pools-graph-section {
    flex-direction: column;
    height: auto;
    max-width: 100% !important;
  }
  .my-pools-left-section {
    display: flex;
    flex-direction: column;
  }
  .my-pools-graph-section {
    max-height: 500px;
    order: 3;
  }
  .my-pools-left-section .pool-rows-container {
    margin-bottom: 20px;
  }
  .my-pools-small-widgets-inner-wrapper {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
  }
  .my-pools-small-widgets-wrapper {
    display: flex;
    flex-direction: column;
  }
  .balance-manager-wrapper, .my-pools-collect-wrapper {
    flex-direction: column;
    max-width: 100%;
  }
  .my-pools-wrapper .lending-pool-close-modal-wrapper {
    display: none;
  }
  .my-pools-wrapper .my-pools-right-graph {
    margin-top: 16px;
  }

}/*# sourceMappingURL=MyPools.css.map */