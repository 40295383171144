.pool-reactions-container {
  max-height: 137px;
  overflow-y: auto;
  margin-bottom: 5px;
}
.pool-reactions-container::-webkit-scrollbar {
  width: 6px;
}
.pool-reactions-container::-webkit-scrollbar-track {
  background: #f9fafb;
}
.pool-reactions-container::-webkit-scrollbar-thumb {
  background: var(--silver);
  border-radius: 2px;
}

.no-pool-reactions-container {
  display: flex;
  align-items: center;
  height: 35px;
}
.no-pool-reactions-container svg {
  margin-right: 14px;
  width: 23px;
  height: 23px;
  color: var(--cta-blue);
}

.pool-reaction-container {
  display: flex;
  align-items: center;
  min-height: 35px;
}
.pool-reaction-container:not(:last-child) {
  margin-bottom: 16px;
}
.pool-reaction-container .pool-reaction-left {
  position: relative;
}
.pool-reaction-container .pool-reaction-left .pool-reaction-jazzicon {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}
.pool-reaction-container .pool-reaction-left .pool-reaction-delete-button {
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 100%;
  transition: all ease 0.3s;
}
.pool-reaction-container .pool-reaction-left .pool-reaction-delete-button svg {
  width: 60%;
  height: 60%;
  color: white;
}
.pool-reaction-container .pool-reaction-left .pool-reaction-delete-button:hover {
  opacity: 1;
}
.pool-reaction-container .pool-reaction-right {
  display: flex;
  flex-direction: column;
}
.pool-reaction-container .pool-reaction-right .pool-reaction-message {
  font-size: 13px;
  margin-bottom: 4px;
}
.pool-reaction-container .pool-reaction-right .pool-reaction-metadata {
  display: flex;
  align-items: center;
}
.pool-reaction-container .pool-reaction-right .pool-reaction-metadata span {
  font-size: 10px;
  color: var(--text-grey);
  display: block;
}
.pool-reaction-container .pool-reaction-right .pool-reaction-metadata span a {
  text-decoration: underline;
}
.pool-reaction-container .pool-reaction-right .pool-reaction-metadata .reaction-metadata-separator {
  margin: 0 7px;
  width: 3px;
  height: 3px;
  border-radius: 100%;
  background-color: var(--text-grey);
  transform: translateY(0px);
}/*# sourceMappingURL=PoolReactions.css.map */