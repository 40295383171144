.borrow-filters-wrapper {
  width: 100%;
  display: flex;
  margin-bottom: 21px;
}
.borrow-filters-wrapper .filter-section {
  width: 50%;
}
.borrow-filters-wrapper .filter-section .token-filter-wrapper {
  font-size: 14px;
}
.borrow-filters-wrapper .filter-section .token-filter-wrapper:first-child {
  margin-right: 16px;
}
.borrow-filters-wrapper .borrow-filters-left-section .token-filters {
  display: flex;
}
.borrow-filters-wrapper .borrow-filters-section-header {
  color: white;
  font-weight: 600;
  font-family: "Sora", sans-serif;
  margin-bottom: 12px;
  display: block;
}

.extra-filters {
  height: 40px;
  display: flex;
}
.extra-filters .borrow-date-filter {
  cursor: pointer;
  width: 220px;
  background: var(--white);
  white-space: nowrap;
  margin-right: 16px;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 12px;
  border-radius: 6px;
}
.extra-filters .borrow-date-filter.active {
  background: rgba(238, 238, 238, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.extra-filters .borrow-date-filter.active svg {
  color: white;
}
.extra-filters .borrow-date-filter.active span {
  color: white;
}
.extra-filters .borrow-date-filter .borrow-date-filter-label {
  color: var(--text-grey);
  font-size: 14px;
}
.extra-filters .borrow-date-filter .dropdown-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  border: none;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}
.extra-filters .borrow-date-filter svg {
  margin-right: 7px;
  width: 20px;
}
.extra-filters .borrow-slider-filters-wrapper {
  height: 40px;
  width: 122px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 12px;
  position: relative;
  font-size: 14px;
  border-radius: 6px;
  border: 1px solid white;
  background: var(--white);
  color: var(--black);
  z-index: 20;
}
.extra-filters .borrow-slider-filters-wrapper .dropdown-wrapper {
  width: 222px;
}
.extra-filters .borrow-slider-filters-wrapper .dropdown-wrapper .action-button {
  height: 40px;
}
.extra-filters .borrow-slider-filters-wrapper.active {
  background: rgba(238, 238, 238, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.3);
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
  color: white;
}
.extra-filters .borrow-slider-filters-wrapper img {
  width: 15px;
  height: 15px;
  margin-right: 8px;
}
.extra-filters .borrow-slider-filters-wrapper .filter-count {
  min-width: 20px;
  min-height: 20px;
  background: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  color: var(--black);
  font-size: 11px;
  transform: translateY(1px);
  margin-left: 10px;
}
.extra-filters .borrow-slider-filters-wrapper .dropdown-wrapper {
  top: 120%;
  width: 223px;
  padding: 16px;
}
.extra-filters .borrow-slider-filters-wrapper input {
  background: #f4f4f4;
  border: none;
  border-radius: 4px;
  height: 25px;
  text-align: right;
}
.extra-filters .borrow-slider-filters-wrapper .slider-filter .slider-filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.extra-filters .borrow-slider-filters-wrapper .slider-filter:not(:last-child) {
  margin-bottom: 10px;
}

.available-input-wrapper {
  height: 25px;
  display: flex;
  align-items: center;
}
.available-input-wrapper .dollar-sign {
  display: block;
  transform: translate(15px, -1px);
  color: var(--text-grey);
  font-size: 11px;
}
.available-input-wrapper input {
  width: 73px;
  padding: 0 5px;
}

.apr-input-wrapper {
  display: flex;
  align-items: center;
}
.apr-input-wrapper input {
  width: 45px;
  padding-right: 15px;
}
.apr-input-wrapper .percent-sign {
  display: block;
  position: absolute;
  right: 20px;
  color: var(--text-grey);
  font-size: 11px;
}
.apr-input-wrapper .value-label {
  transform: translateX(-8px);
}

.value-label {
  font-size: 12px;
}

.calendar-wrapper {
  z-index: 100;
}

.calendar-button-wrapper {
  background-color: white;
  display: flex;
  overflow: hidden;
  padding: 16px;
  border-top: 1px solid var(--line);
}
.calendar-button-wrapper button:first-child {
  margin-right: 16px;
}

.date-range-wrapper {
  display: flex;
}
.date-range-wrapper span {
  font-size: 13px;
  color: var(--black);
}
.date-range-wrapper .dash {
  display: block;
  margin: 0 3px;
}

@media screen and (max-width: 1180px) {
  .borrow-filters-wrapper {
    flex-direction: column;
  }
  .extra-filters > div {
    width: 50% !important;
  }
  .filter-section {
    width: 100% !important;
  }
  .token-filters {
    width: 100%;
    margin-bottom: 20px;
  }
  .token-filters .token-filter-wrapper {
    width: 50%;
  }
}

/*# sourceMappingURL=BorrowFilters.css.map */