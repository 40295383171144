.pool-requests-container {
  background: var(--white);
  border-radius: 6px;
  margin-top: 16px;
  padding-top: 16px;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  overflow: hidden;
}
.pool-requests-container .term-rate-header {
  transform: translateX(-15px);
}
.pool-requests-container .apr-header {
  transform: translateX(0px);
}
.pool-requests-container .expiry-header {
  transform: translateX(-10px);
}
.pool-requests-container .line-tabs-wrapper {
  margin-bottom: 25px;
  margin-left: 16px;
  width: calc(95% + 1px);
}
.pool-requests-container .available-header {
  transform: translateX(-35px);
}

.inner-pool-requests-container {
  max-height: 184px;
  overflow-y: auto;
}
.inner-pool-requests-container::-webkit-scrollbar {
  width: 6px;
}
.inner-pool-requests-container::-webkit-scrollbar-track {
  background: #f9fafb;
}
.inner-pool-requests-container::-webkit-scrollbar-thumb {
  background: var(--line);
  border-radius: 2px;
}

.pool-request-row-wrapper {
  font-size: 14px;
}
.pool-request-row-wrapper .expiry-column {
  white-space: nowrap;
  transform: translateX(-10px);
}
.pool-request-row-wrapper .term-rate-column {
  transform: translateX(-15px);
}
.pool-request-row-wrapper .apr-column {
  transform: translateX(0px);
}
.pool-request-row-wrapper .available-column {
  transform: translateX(-35px);
}
.pool-request-delete-button {
  position: absolute;
  right: 15px;
  top: 50%;
  width: 15px;
  color: var(--cherry);
  transform: translateY(-50%);
  outline: none;
  transition: all ease 0.3s;
}
.pool-request-delete-button.pending {
  color: var(--line);
  cursor: not-allowed;
}
.pool-requests-container-header {
  position: relative;
}
.pool-request-link {
  display: flex;
  align-items: center;
  position: absolute;
  top: -5px;
  right: 20px;
  color: var(--black);
  font-size: 13px;
  cursor: pointer;
}
.pool-request-link svg {
  width: 10px;
  margin-left: 6px;
  transform: translateY(1px);
}