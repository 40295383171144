.token-filter-wrapper {
  position: relative;
  width: 142px;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid white;
  border-radius: 6px;
  font-size: 14px;
  padding-left: 14px;
  cursor: pointer;
  background: rgba(238, 238, 238, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  color: white;
}

.token-filter-wrapper.single {
  background-color: white;
  color: var(--text-grey);
}

.token-filter-logo-wrapper {
  display: flex;
  align-items: center;
}

.token-filter-logo-wrapper img {
  width: 20px;
  height: 20px;
  margin-right: 7px;
}

.token-filter-count {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: var(--white);
  color: var(--black);
  border-radius: 100%;
  width: 20px;
  height: 20px;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 1px;
}

.token-filter-wrapper svg {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 15px;
}

.token-filter-token-wrapper:not(:last-child) {
  margin-bottom: 3px;
}

.no-tokens-found-message {
  display: flex;
  height: 40px;
  align-items: center;
  padding: 0 11px;
}

.no-tokens-found-message svg {
  position: relative;
  top: 0;
  right: 0;
  transform: translateY(-1px);
  margin-right: 7px;
  stroke: var(--cta-blue);
}

.token-filter-token-wrapper {
  display: flex;
  justify-content: space-between;
  height: 40px;
  align-items: center;
  padding: 0 10px;
}

.token-filter-token-left span {
  max-width: 73px;
  white-space: nowrap;
  overflow-x: hidden;
}

.token-filter-token-wrapper:hover {
  background: #f9fafb;
}

.token-filter-token-wrapper input[type=checkbox] {
  min-width: 17px;
  min-height: 17px;
  border-radius: 4px;
  margin: 0;
  transition: all ease 0.2s;
  position: relative;
}

.token-filter-token-wrapper input[type=checkbox]:checked {
  border: 2px solid var(--cta-blue);
}

.token-filter-token-wrapper input[type=checkbox]:checked:after {
  content: "";
  position: absolute;
  width: 1ex;
  height: 0.2ex;
  top: 49%;
  left: 52%;
  border: 2px solid var(--cta-blue);
  border-top: none;
  border-right: none;
  transform: translate(-50%, -60%) rotate(-45deg);
}

.token-filter-token-wrapper input[type=checkbox] {
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  border: 2px solid #d0d5dd;
  font-size: 1em;
}

.token-filter-search {
  width: 100%;
}

.token-filter-token-left {
  display: flex;
  align-items: center;
}

.token-filter-token-wrapper img {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  margin: 0 9px;
}

.token-filter-token-price {
  color: var(--davy-grey);
}

.token-filter-wrapper .dropdown-wrapper {
  width: 250px;
  max-height: 300px;
  left: 0 !important;
  top: 120%;
  padding: 0;
  padding-top: 2px;
}

.token-filter-top-section {
  padding: 0 10px;
}

.token-filter-bottom-section {
  overflow-y: auto;
}

.token-filter-bottom-section::-webkit-scrollbar {
  width: 8px;
}

.token-filter-bottom-section::-webkit-scrollbar-track {
  background: #f9fafb;
}

.token-filter-bottom-section::-webkit-scrollbar-thumb {
  background: var(--silver);
  border-radius: 2px;
}

.token-filter-search-wrapper {
  margin-bottom: 10px;
  height: 35px;
  min-height: 35px;
  border-radius: 7px;
  outline: none;
  border: 1px solid #D4D8DF;
  position: relative;
  margin-top: 10px;
}

.token-filter-search-icon {
  position: absolute;
  right: 9px !important;
  stroke: var(--text-grey);
}

.token-filter-search {
  min-width: 100%;
  height: 100%;
  border: none;
  border-radius: 7px;
  border: none;
  padding-top: 0;
  padding-bottom: 0;
  outline: none;
  background: none;
  padding-left: 15px;
  transition: all ease 0.3s;
}
.token-filter-search:focus {
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #DCECFF;
}

.token-filter-select-all {
  min-height: 40px;
  background-color: var(--selected-blue);
  margin-bottom: 5px;
  text-align: center;
  border-radius: 6px;
  display: flex;
  justify-items: center;
  align-items: center;
  color: var(--cta-blue);
  font-weight: 600;
  cursor: pointer;
}
.token-filter-select-all span {
  width: 100%;
}

.supported-tokens-wrapper {
  overflow-y: auto;
}

.token-filter-wrapper .tab-container {
  margin: 0;
  width: 100%;
  margin-top: 10px;
}

.token-filter-wrapper .tab-item {
  font-size: 13px;
  font-weight: 500;
}

.token-filter-wrapper .tab-slider {
  width: 50%;
}

.token-filter-wrapper .tab-slider.borrow, .tab-slider.add, .tab-slider.repay {
  left: 50%;
}

.token-filter-wrapper .tab-slider.deposit {
  left: 0%;
}/*# sourceMappingURL=TokenFilter.css.map */