.dropdown-wrapper {
  position: absolute;
  top: 75%;
  right: 0;
  width: auto;
  z-index: 10;
  background: var(--white);
  color: var(--dark-jungle-green);
  border: 1px solid var(--pale-lavender);
  border-radius: 6px;
  box-shadow: 0px 0px 10px rgba(59, 58, 67, 0.2);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  z-index: 99;
  padding: 7px 0;
}
.dropdown-item {
  transition: all ease 0.2s;
  display: flex;
  align-items: center;
  font-size: 13px;
  z-index: 10;
  padding-left: 11px;
}

.dropdown-item.copy-pool-button {
  color: var(--cta-blue);
}

.dropdown-item a {
  display: flex;
  align-items: center;
}

.dropdown-item svg, .dropdown-item img {
  width: 13px;
  height: 13px;
  transform: translateY(1px);
  margin-right: 10px;
}