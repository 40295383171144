.slideshow-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  width: 100%;
  height: auto;
  left: 904px;
  top: 80px;
  background: var(--white);
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  border-radius: 6px;
}

.intro-media-wrapper {
  width: 100%;
  height: 192px;
  background: var(--orange-gradient);
  border-radius: 10px;
  position: relative;
  margin-bottom: 20px;
  animation: move 5s alternate infinite;
  background-position: 100%;
  background-size: 400% 400%;
}
.intro-media-wrapper svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: auto;
  transition: all ease 1s;
  color: var(--white);
  cursor: pointer;
}
.intro-media-wrapper svg:hover {
  transform: translate(-50%, -50%) rotate(360deg) scale(1.1);
}

.intro-dots-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
}
.intro-dots-wrapper .intro-dot {
  margin: 0 6px;
  transition: all ease 0.5s;
  width: 8px;
  cursor: pointer;
  height: 8px;
  background: hsl(28, 100%, 89%);
  border-radius: 100%;
}
.intro-dots-wrapper .intro-dot.active {
  background: hsl(26, 89%, 61%);
}

.content-text-wrapper {
  color: var(--black);
  text-align: center;
  margin-bottom: 20px;
}
.slideshow-content-wrapper {
  max-width: 100%;
}
.content-text-wrapper h1 {
  font-size: 16px;
  margin-bottom: 8px;
  font-weight: 400;
  font-family: "Sora", sans-serif;
}
.content-text-wrapper p {
  color: hsl(220, 13%, 46%);
  font-size: 14px;
  line-height: 20px;
  padding: 0 0.5em;
  min-height: 60px;
  font-family: "Inter", sans-serif;
}

.content-media-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 37px;
  max-width: 100%;
}

.content-media-wrapper img {
  height: 184px;
  width: auto;
}

.intro-buttons {
  display: flex;
  width: 100%;
}
.intro-buttons button {
  width: 50%;
  height: 44px;
  border-radius: 8px;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  border: none;
  cursor: pointer;
  transition: all ease 0.3s;
}
.intro-buttons button:hover {
  filter: brightness(95%);
}
.intro-buttons button:first-child {
  margin-right: 12px;
}
.intro-buttons button:active {
  transform: scale(0.97);
}
.intro-buttons .intro-back-button {
  border: 1px solid #D0D5DD;
  background: var(--white);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  font-weight: 600;
}
.intro-buttons .intro-next-button {
  background: var(--orange-gradient);
  color: var(--white);
  font-weight: 600;
}

@keyframes move {
  0%{
    background-position: 0% 50%;
  } 
  100% {
    background-position: 100% 0%;
  }
}