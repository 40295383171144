.set-rollover-wrapper {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.set-rollover-input-wrapper {
  width: 100%;
  height: auto;
  position: relative;
  height: 45px;
  border-radius: 6px;
  border: none;
  background: var(--white-smoke);
  color: var(--dark-jungle-green);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 15px;
}

.set-rollover-input-wrapper.hidden {
  display: none;
}

.set-rollover-input-preview {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: var(--davy-grey);
}

.set-rollover-input-preview .loading-skeleton {
  min-width: 95%;
  margin: auto;
}

.set-rollover-input-wrapper svg {
  margin-right: 5px;
}

.set-rollover-wrapper .action-button {
  margin: 0;
  width: 100%;
  height: 40px;
  border-radius: 6px;
}

.set-rollover-wrapper .dropdown-wrapper {
  left: 0;
  top: 120%;
  width: 100%;
}

.no-potential-rollovers {
  color: var(--davy-grey);
  padding-left: 13px !important;
}

.potential-rollover-header-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 7px;
}

.potential-rollover-header-wrapper span {
  font-size: 12px;
  color: var(--text-grey);
  padding: 0 5px;
}

.potential-rollover-header-wrapper span:first-child {
  transform: translateX(5px);
}

.potential-rollover-header-wrapper span:last-child {
  transform: translateX(-15px);
}

.dropdown-wrapper .potential-rollover {
  padding-right: 20px;
}