.pool-stats-wrapper {
  background-color: var(--white);
  border: 1px solid var(--pale-lavender);
  outline: none;
  width: 500px;
  height: auto;
  padding: 1em;
  border-radius: 6px;
}

.stats-header {
  font-weight: bold;
  width: 100%;
  display: block;
  margin-bottom: 1em;
  font-size: 16px;
  color: var(--black);
}

.stats-container-stat {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
}

.stat-label {
  color: var(--auro-metal-saurus);
}

.stat-value {
  color: var(--black);
}

.my-pools-graph-title .__react_component_tooltip {
  max-width: 1000px;
  padding: 0;
  background-color: rgba(0,0,0,0) !important;
  opacity: 1 !important;
  box-shadow: 0px 1px 10px rgba(59, 58, 67, 0.2);
  border-radius: 6px;
  z-index: 10;
}

.my-pools-graph-title .__react_component_tooltip::after {
  border-bottom-color: var(--white) !important;
}

.__react_component_tooltip .pool-stats-wrapper {
  background-color: var(--white);
  border: none;
  border-radius: 6px;
  margin: 0;
}

@media screen and (max-width: 600px) {
  .pool-stats-wrapper {
    width: 300px;
  }
  .stats-container-stat {
    font-size: 13px;
  }
}