.create-pool-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
}

.grecaptcha-badge {
  opacity: 0 !important;
}

.create-pool-wrapper .pool-filters-search-wrapper {
  display: none;
}

.create-pool-confirm-button {
  margin-bottom: 15px;
}

.create-pool-wrapper .line-tabs-wrapper {
  margin-bottom: 40px;
}

.create-pool-wrapper .action-button-container {
  width: 100%;
}

.create-pool-wrapper .widget-wrapper {
  padding: 16px;
  background: var(--white);
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
}

.create-pool-wrapper .token-input-container {
  display: flex;
  flex-direction: row;
  height: auto;
}

.create-pool-wrapper .increment-selector-wrapper {
  height: 45px;
}

.create-pool-wrapper .increment-selector-wrapper .increment-selector-button {
  min-width: 45px;
  min-height: 45px;
}

.create-pool-wrapper .increment-selector-wrapper input {
  width: 45px;
  margin: 0 10px;
  width: 60px !important;
}

.create-pool-wrapper .info-title-container {
  margin-top: 15px;
}

.info-title.apr-title {
  justify-content: flex-end;
}

.create-pool-wrapper .info-section-container.block {
  margin-top: 15px;
}

.create-pool-wrapper .increment-selector-percent {
  top: 32% !important;
  right: 35% !important;
}

.create-pool-graph-container {
  width: 100%;
  height: 250px;
  width: 50px;
  flex: 4.4;
}

.create-pool-left {
  flex: 1.5;
  margin-right: 16px;
}

.create-pool-right {
  flex: 1;
  width: 440px;
}

.token-dropdown {
  margin-top: 5px;
}

.row-title {
  font-size: 14px;
}

.info-title {
  min-width: 100px;
}

.request-notice {
  margin-bottom: 16px;
}

.info-title.lend-ratio-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transform: translateY(4px);
}

.info-title.lend-ratio-title span {
  display: flex;
  align-items: center;
}

.toggles-container {
  width: 100%;
  margin-top: 10px;
}

.toggles-container .toggle-wrapper {
  margin: 0.6em 0;
}

.toggle-wrapper {
  text-align: right;
  display: flex;
  align-items: center;
  color: #B8B8BA;
  align-items: center;
  justify-content: space-between;
}

.toggle-wrapper .toggle-wrapper-label {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
  color: var(--cta-blue);
}

.toggle-wrapper .toggle-wrapper-switch {
  width: 44px;
  height: 22px;
  background: var(--silver);
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  transition: all ease 0.3s;
}

.toggle-wrapper .toggle-wrapper-switch.checked {
  background: var(--cta-blue);
}

.toggle-wrapper .toggle-wrapper-switch .toggle-wrapper-slider {
  width: 14px;
  height: 14px;
  border-radius: 100%;
  position: absolute;
  background: var(--white-smoke);
  top: 50%;
  transform: translateY(-50%);
  left: 4px;
  transition: all ease 0.3s;
}

.toggle-wrapper .fee-checkbox {
  cursor: pointer;
  opacity: 0;
  width: 0;
  height: 0;
}

.undercollateralized-notice {
  padding: 10px;
  display: block;
  background: rgba(0, 0, 0, 0.3);
  color: var(--white);
  border-radius: 5px;
}

.undercollateralized-alert {
  background: var(--magnolia) !important;
  margin-top: 0.5em;
  font-size: 1em;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.toggle-wrapper .fee-checkbox:checked + .toggle-wrapper-slider {
  left: 57%;
}

.toggle-wrapper svg {
  width: 15px;
  margin: 0 5px;
}

.expired-license-filter {
  min-width: 175px;
  max-width: 175px;
}

.protocol-fee-message {
  border-radius: 10px !important;
  margin-top: 1em;
}

.create-pool-page-status-wrapper {
  display: flex;
  justify-content: space-between;
  height: 1px;
  width: 95%;
  margin: auto;
  margin-bottom: 45px;
}
.create-pool-page-status-wrapper .create-pool-page-status {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 10pt;
}
.create-pool-page-status-wrapper .status-label {
  background-color: red;
}
.create-pool-page-status-wrapper .MuiSlider-thumb {
  background-color: none;
  border: none;
  display: none;
}
.create-pool-page-status-wrapper .MuiSlider-marked {
  color: #fff !important;
}
.create-pool-page-status-wrapper .MuiSlider-rail, .create-pool-page-status-wrapper .MuiSlider-track {
  background-color: var(--text-light-grey) !important;
}
.create-pool-page-status-wrapper .MuiSlider-rail, .create-pool-page-status-wrapper .MuiSlider-track {
  height: 2px !important;
}
.create-pool-page-status-wrapper .MuiSlider-track {
  background-color: var(--cta-blue) !important;
}
.create-pool-page-status-wrapper .MuiSlider-markLabel {
  top: 5px;
  color: var(--white);
  font-size: 13px;
  font-family: "Sora", sans-serif;
}
.create-pool-page-status-wrapper .MuiSlider-markLabelActive {
  color: var(--cta-blue);
  display: none;
}
.create-pool-page-status-wrapper .MuiSlider-mark {
  background-color: var(--text-light-grey) !important;
  height: 24px !important;
  width: 24px !important;
  opacity: 1;
  border-radius: 100% !important;
  transform: translate(-50%, -45%);
  outline: 4px solid white;
}

.create-pool-page-status-wrapper .MuiSlider-markActive {
  background-color: var(--cta-blue) !important;
  border: 1px solid var(--cta-blue) !important;
  outline: 4px solid white !important;
}
.create-pool-page-status-wrapper .MuiSlider-markActive:after {
  content: "";
  position: absolute;
  width: 50%;
  height: 20%;
  top: 49%;
  left: 50%;
  border: 2px solid white;
  border-radius: 0;
  border-top: none;
  border-right: none;
  background: none !important;
  transform: translate(-50%, -60%) rotate(-45deg);
}

.next-page-button {
  width: 100%;
  height: 55px;
  background: var(--orange-gradient);
  border: none;
  border-radius: 10px;
  color: var(--white);
  font-size: 12pt;
  cursor: pointer;
}

@media screen and (max-width: 1180px) {
  .create-pool-wrapper {
    flex-direction: column;
  }
  .create-pool-right {
    width: 100% !important;
    margin-bottom: 20px;
  }
  .create-pool-left {
    order: 2;
    margin-right: 0;
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 738px) {
  .license-table-wrapper {
    overflow: hidden;
  }
  .token-input-container.token-dropdown {
    flex-direction: column;
  }
  .inner-token-input-container {
    width: 100%;
    margin-right: 0 !important;
    margin-bottom: 5px;
  }
  .toggle-wrapper .toggle-wrapper-label {
    font-size: 13px;
  }
  .lend-ratio-input {
    font-size: 12pt;
  }
  .create-pool-wrapper .increment-selector-wrapper input {
    font-size: 12pt;
  }
}/*# sourceMappingURL=CreatePool.css.map */