.strategy-selector-wrapper {
  width: 100%;
  height: auto;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  margin-bottom: 18px;
  position: relative;
}

.strategy-selector-wrapper .dropdown-wrapper {
  width: 100%;
  top: 110%;
  padding: 0;
}

.strategy-selector-input-wrapper {
  width: 100%;
  height: auto;
  position: relative;
  height: 45px;
  border-radius: 7px;
  border: none;
  background: var(--background);
  color: var(--dark-jungle-green);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.strategy-selector-input-wrapper svg {
  margin-right: 10px;
  stroke: var(--cta-blue);
  width: 20px;
  color: var(--cta-blue);
}

.strategy-option-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  height: 45px;
  cursor: pointer;
  transition: all ease 0.3s;
}

.strategy-option-wrapper .loading-skeleton {
  min-width: 50px;
}

.strategy-option-wrapper .strategy-address {
  font-size: 14px;
  color: var(--select-text);
  text-decoration: underline;
  display: flex;
  align-items: center;
}

.strategy-option-wrapper .strategy-address svg {
  margin-left: 6px;
  stroke: var(--cta-blue);
  width: 14px;
  color: var(--cta-blue);
}

.strategy-option-wrapper .strategy-name {
  display: flex;
  font-size: 14px;
  align-items: center;
}

.strategy-option-wrapper .strategy-name .strategy-rate {
  font-size: 11px;
  color: var(--cta-blue);
  margin-left: 5px;
  transform: translateY(1px);
  display: block;
}

.strategy-option-wrapper:hover {
  background: var(--input-grey);
  border-radius: 7px;
}/*# sourceMappingURL=StrategySelector.css.map */