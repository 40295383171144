.options-menu-wrapper {
  width: auto;
  height: auto;
  position: absolute;
  top: 120%;
  right: 0;
  background: var(--white);
  padding: 15px 16px;
  box-shadow: 0px 0px 10px rgba(59, 58, 67, 0.2);
  border-radius: 6px;
  transition: all ease 0.3s;
  z-index: 10;
}

.options-menu-option {
  display: flex;
  align-items: center;
  color: var(--dark-jungle-green);
  width: 105px;
  transition: all ease 0.3s;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 13px;
  display: flex;
  height: 18px;
}
.options-menu-option.bug-bounty {
  margin-top: 20px !important;
}
.options-menu-option:last-child {
  margin-top: 20px !important;
}
.options-menu-option svg {
  width: 15px;
  min-width: 15px;
  color: var(--dark-jungle-green);
  margin-right: 15px;
}
.options-menu-option img {
  width: 16px;
  margin-right: 15px;
}
.options-menu-option:not(:first-child) {
  margin-top: 13px;
}
.options-menu-option:hover {
  transform: translateX(3px);
}/*# sourceMappingURL=OptionsMenu.css.map */