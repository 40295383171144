.apr-container {
  height: 40px;
  font-size: 27px;
  min-width: 100px;
  max-width: 100px;
  color: var(--cta-blue);
  display: flex;
  align-items: center;
}

.apr-container .percent {
  font-size: 21px;
  transform: translate(2px, 2px);
}

.apr-container .monthly {
  font-size: 20px;
  font-weight: 500;
}

.apr-container .yearly {
  font-size: 0.5em;
  transform: translate(-4px, 8px);
  
}