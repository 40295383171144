.network-select-wrapper {
  width: 148px;
  height: auto;
  position: absolute;
  top: 120%;
  left: 334px;
  background: var(--white);
  padding: 15px 13px;
  box-shadow: 0px 0px 10px rgba(59, 58, 67, 0.2);
  border-radius: 6px;
  transition: all ease 0.3s;
  z-index: 10;
}

.network-select-wrapper.mobile {
  top: 130%;
  left: 0;
  width: 173px;
}

.network-select-option {
  display: flex;
  align-items: center;
  color: var(--black);
  width: 100%;
  transition: all ease 0.3s;
  background: none;
  border: none;
  cursor: pointer;
  white-space: nowrap;
}

.network-select-option img {
  width: 20px;
  margin-right: 15px;
}

.network-select-option:not(:first-child) {
  margin-top: 15px;
}

.network-select-option:hover {
  transform: translateX(3px);
}