.rate-simulation-wrapper {
  padding: 15px;
  background: var(--white);
  border-radius: 6px;
  box-shadow: 0px 1px 10px rgba(59, 58, 67, 0.1);
  font-size: 10px;
  height: 100%;
  position: relative;
}

.rate-simulation-wrapper .legend-item-2 {
  display: none !important;
}

.rate-simulation-wrapper .no-data-message {
  position: absolute;
  top: 55%;
  left: 50%;
  font-size: 12px;
  transform: translateX(-50%); 
  color: var(--auro-metal-saurus);
  z-index: 10;
}

.rate-simulation-title {
  font-size: 16px;
  font-weight: 500;
  position: relative;
}

.simulation-loader {
  position: absolute !important;
  right: 3px;
  top: 0;
}

.recharts-default-legend {
  display: flex;
  justify-content: flex-end;
}

.recharts-legend-item {
  margin-bottom: 0 !important;
  display: flex !important;
}

.recharts-legend-item svg {
  filter: drop-shadow(0 2px 4px rgba(31, 33, 65, 0.16));
}

.recharts-surface {
  border-radius: 10px;
}

.recharts-symbols {
  filter: drop-shadow(0px 2px 5px rgba(73, 49, 207, 0.4));
}

.recharts-cartesian-axis-tick text {
  color: var(--onyx);
  font-size: 12px;
}

.recharts-tooltip-wrapper {
  border-radius: 5px;
  background: var(--magnolia);
  outline: var(--pale-lavender);
  border: 1px solid var(--pale-lavender);
  box-shadow: 0px 1px 10px rgba(59, 58, 67, 0.08);
  font-family: "Poppins", sans-serif;
}

.graph-tooltip {
  padding: 0px 10px;
}

.recharts-surface .recharts-layer path:nth-child(2) {
  filter: drop-shadow(0px 2px 5px rgba(73, 49, 207, 0.4));
}