.verification-loading-wrapper {
  width: 80%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.verification-loading-wrapper img {
  width: 100%;
  height: 100%;
  filter: drop-shadow(0px 0px 10px rgba(33, 33, 33, 0.5));
  margin-bottom: 5px;
}

.verification-loading-wrapper span {
  font-family: "Inter", sans-serif;
  font-size: 20px;
}

.blocked-ip-message {
  background-color: white;
  padding: 2em;
  border-radius: 10px;
  font-family: "Inter", sans-serif;
  box-shadow: 0px 1px 10px rgba(59, 58, 67, 0.08);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.blocked-ip-message h1 {
  font-size: 25px;
  margin: 0;
  margin-bottom: 15px;
}

.blocked-ip-message span {
  font-size: 20px;
  text-align: center;
  width: 100%;
}