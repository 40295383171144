.dashboard-wrapper {
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  max-width: 1106px;
}

.topnav-divider {
  position: absolute;
  left: -100%;
  top: -22px;
  width: 300%;
  height: 1px;
  background: hsla(218, 15%, 85%, 1);
  opacity: 0.5;
  z-index: 1;
}

.dashboard-content {
  display: flex;
  flex: 1;
  position: relative;
}

.routes-container {
  z-index: 2;
  width: 100%;
  height: auto;
  overflow-y: auto;
  overflow: visible;
}

@media screen and (max-width: 1180px) {
  .dashboard-wrapper {
    max-width: 738px;
  }
  .topnav-divider {
    display: none;
  }
}


@media screen and (max-width: 738px) {
  .dashboard-wrapper {
    width: 95%;
  }
  .dashboard-content {
    margin-top: 90px;
  }
}

@supports (-webkit-touch-callout: none) {
  .dashboard-content {
    margin-top: 65px;
  }
}