.clear-borrowers-wrapper {
  display: flex;
  cursor: pointer;
  color: var(--text-grey);
  font-size: 11pt;
  margin-right: 2px;
}
.clear-borrowers-wrapper svg {
  color: var(--red-accent);
  width: 20px;
  margin-left: 3px;
  transform: translateY(-2px);
}/*# sourceMappingURL=ClearBorrowers.css.map */