.transaction-review {
  margin-top: 16px;
  background-color: var(--white);
  width: 100%;
  height: auto;
  padding: 16px;
  overflow: visible;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  transition: all ease 0.5s;
  font-size: 14px;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
}

.transaction-review.floating {
  max-width: 500px;
}

.transaction-review .transaction-info {
  width: 100%;
  margin: auto;
  margin-bottom: 16px;
}

.review-header {
  color: var(--black);
  cursor: pointer;
  display: flex;
  flex: 1 1;
  font-size: 14px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 11px;
  width: 100%;
  font-weight: 500;
}

.review-header.hide {
  display: none;
}

.transaction-review .transaction-info h1 {
  margin-top: 0;
  margin-bottom: 5px;
  color: var(--white);
}

.transaction-review .transaction-info .info-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.transaction-review .transaction-info .info-item .loading-skeleton {
  height: 100% !important;
  width: 50px;
}

.transaction-review .transaction-info .info-item span {
  color: var(--white);
}

.transaction-review .transaction-info .info-item .info-left {
  color: var(--text-grey);
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 18px;
}

.info-item .info-left svg {
  width: 15px;
  margin-left: 5px;
  transform: translateY(0px);
  color: var(--cta-blue);
}

.transaction-review .transaction-info .info-item a {
  color: var(--black);
}

.transaction-review .transaction-info .info-item span:last-child {
  color: var(--black) !important;
  display: flex;
  justify-content: center;
  align-items: center;
}