.pool-status-container {
  position: absolute;
  left: 10px;
  top: 2px;
  display: flex;
  z-index: 10;
}

.pool-status-container .pool-status-wrapper {
  max-width: 17px;
  width: 17px;
  min-width: 17px;
  overflow: hidden;
  height: 17px;
  border-radius: 10px;
  box-shadow: 0px 1px 10px rgba(59, 58, 67, 0.2);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  transition: all ease 0.3s;
  white-space: nowrap;
}

.pool-status-wrapper:nth-child(2){
  transform: translateX(-5px);
}

.pool-status-wrapper:nth-child(3){
  transform: translateX(-5px);
}

.pool-status-wrapper:nth-child(4){
  transform: translateX(-5px);
}


.pool-status-wrapper.private-status {
  background: #4930CE;
  z-index: 2;
}

.pool-status-wrapper.paused-status {
  background: #F2286D;
  z-index: 2;
}

.pool-status-wrapper.factory-paused-status {
  background: #FA756D;
  z-index: 2;
}

.pool-status-wrapper.rollover-status {
  background: #A566E7;
  z-index: 1;
  /* hide by default */
  display: none;
}

.pool-status-wrapper.featured-status {
  background: var(--sea-green);
  z-index: 2;
  /* hide by default */
  display: none;
}

.pool-status-wrapper.active {
  min-width: 55px;
}

.pool-status-wrapper.factory-paused-status.active {
  min-width: 96px;
}

.my-pools-wrapper .rollover-status {
  display: flex;
}

.my-pools-wrapper .featured-status {
  display: flex;
}