.topnav-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 35px;
  margin-top: 15px;
  z-index: 10;
  font-family: "Sora", sans-serif;
}

.social-logos {
  display: flex;
  justify-content: space-around;
  position: absolute;
  width: 100%;
  bottom: 0;
  margin-bottom: 2em;
}

.social-logos a {
  width: 20px;
  height: 20px;
  margin: 0 10px;
  transition: all ease 0.3s;
  cursor: pointer;
}

.social-logos a:hover {
  transform: scale(1.5);
}

.social-logos a img {
  width: 100%;
}

.topnav-header.mobile {
  display: none;
  position: relative;
}

.topnav-header {
  color: var(--lavender-indigo);
  font-weight: thin;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  transition: all ease 0.3s;
  font-size: 17px;
  cursor: pointer;
}

.topnav-header .vendor-logo {
  width: 44px;
  height: auto;
  margin-right: 37px;
  transition: all ease 0.5s;
}

.topnav-header .vendor-logo:hover {
  transform: scale(1.1);
}

.topnav-header:hover span {
  position: relative;
  overflow: hidden;
}

.topnav-button {
  width: 148px;
  height: 40px;
  padding: 0 10px;
  border-radius: 6px;
  color: #AEAFB7;
  background-color: var(--dark-purple);
  display: flex;
  align-items: center;
  transition: all ease 0.3s;
  cursor: pointer;
  border: none;
  margin-left: 16px;
  box-shadow: 0px 1px 10px rgba(59, 58, 67, 0.1);
  justify-content: center;
}

.topnav-button:hover {
  transform: scale(1.02);
}

.wallet-connect-button {
  color: var(--cta-blue);
  font-size: 13px;
  font-weight: 400;
  background: rgba(238, 238, 238, 0.5);
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
  font-family: "Sora", sans-serif;
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: var(--white);
}

.topnav-buttons {
  display: flex;
  position: relative;
  justify-content: flex-end;
  flex: 1;
}

.topnav-button span {
  display: flex;
  justify-content: center;
  align-items: center;
}

.options-button {
  width: 40px;
  height: 40px;
  padding: 0;
  display: flex;
  justify-content: center;
  color: var(--cta-blue);
  background: var(--white);
  position: relative;
  box-shadow: 0px 1px 5px rgba(59, 58, 67, 0.0784314);
}

.options-button:hover {
  transform: scale(1.05);
}

.options-button:active {
  transform: scale(1);
}

.wallet-connect-button div {
  width: 20px;
  height: 20px;
  margin-left: 1em;
}

.network-connect-button {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  background-color: var(--white);
  color: var(--dark-jungle-green);
  position: relative;
}

.network-connect-button svg {
  width: 20px;
  color: var(--black);
}

.network-connect-button span {
  margin-right: 5px;
  color: var(--black);
  white-space: nowrap;
}

.network-connect-button span svg {
  width: 15px;
  margin-right: 6px;
}

.network-connect-button img {
  margin-right: 0.7em;
  width: 20px;
  height: 20px;
}

.nav-link-container {
  display: flex;
}

.nav-links {
  height: auto;
}

.nav-link-container .nav-link {
  margin-right: 43px;
  display: flex;
  align-items: center;
  color: white;
  transition: all ease 0.3s;
  margin-right: 2em;
}

.nav-link {
  font-size: 16px;
  position: relative;
}
.nav-link::after {
  content: "";
  transition: all ease 0.3s;
  position: absolute;
  bottom: -25px;
  left: 0;
  width: 0;
  height: 3px;
  background: var(--white);
  border-radius: 10px;
}

.nav-link svg {
  margin-right: 1em;
  width: 15px;
  height: 15px;
}

.nav-link.active {
  font-weight: bold;
  position: relative;
}
.nav-link.active::after {
  content: "";
  position: absolute;
  bottom: -25px;
  left: 0;
  width: 100%;
  height: 3px;
  background: white;
  border-radius: 10px;
  z-index: 10;
}

.mobile-menu-button {
  display: none;
  position: relative;
  color: var(--cta-blue);
}

.tvl-wrapper {
  font-size: 13px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  border-radius: 6px;
  min-width: 121px;
  height: 40px;
  padding: 0 15px;
  color: var(--white);
  font-weight: 400;
  background: rgba(238, 238, 238, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.3);
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
  font-family: "Sora", sans-serif;
}
.tvl-wrapper img {
  margin-right: 11px;
}
.dark-mode-button {
  color: white;
  background: none;
  border: none;
  margin-right: 16px;
  cursor: pointer;
  outline: none;
  transform: translateY(2px);
}


.tvl-wrapper .MuiSkeleton-root {
  min-width: 48px;
  margin-left: 3px;
}
@keyframes colorful {
  from {
    color: #8d4ad4;
  }
  to {
    color: #ff5777;
  }
}
@keyframes slides {
  0% {
    left: -100%;
  }
  100% {
    left: 110%;
  }
}
@media screen and (max-width: 1180px) {
  .mobile-menu-button {
    display: block;
  }
  .topnav-header.mobile {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    margin-bottom: 22px;
    margin-top: 0px;
    position: absolute;
    top: 0;
    left: 0;
    background: white;
    padding: 0 1em;
    z-index: 50;
    box-shadow: 0px 1px 10px rgba(59, 58, 67, 0.1);
  }
  .topnav-header.desktop, .topnav-wrapper.desktop {
    display: none;
  }
  .topnav-buttons {
    flex-direction: column;
    align-items: flex-end;
    display: none;
  }
  .topnav-buttons .topnav-button {
    margin-bottom: 15px;
  }
}/*# sourceMappingURL=TopNav.css.map */