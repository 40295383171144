.upgrade-implementation-wrapper {
  width: 100%;
  height: auto;
  background: var(--white);
  box-shadow: 0px 1px 10px rgba(59, 58, 67, 0.1);
  border-radius: 6px;
  padding: 12px 17px;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.upgrade-implementation-wrapper a {
  text-decoration: underline;
}

.upgrade-implementation-wrapper .action-button-container {
  margin: 0;
  width: 100%;
  margin-top: 15px;
}