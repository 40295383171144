:root {
  --blue-accent: #3B6AFC;
  --mid-purple: #350F6B;
  --bright-purple: #8646CB;
  --deep-fuchsia: #C551A7;
  --dark-pastel-purple: #9e72cd;
  --midnight-blue: #270e4c;
  --onyx: #06071d;
  --purple-pizzazz: #FC56D2;
  --lavender-indigo: #8d4ad4;
  --ash-grey: #aeafb7;
  --red-accent: #D1495B;
  --almost-white: #FEFFFE;
  --almost-black: #260950;
  --border-color: #9F9F9F;
  --input: #F2F4F8;
  --colorful-gradient: linear-gradient(90deg, #8d4ad4 0%, #ff5777 100%);
  --purple-gradient: linear-gradient(38deg, #4753d8 0%, #954ef7 100%);
  --blue-gradient: linear-gradient(63deg, #4753d8 0%, #804fef 100%);
  --red-gradient: linear-gradient(32deg, #fa756d 0%, #f2286d 100%);
  /* new ui colors*/
  --iris: #4931cf;
  --dark-jungle-green: #131523;
  --han-purple: #593bff;
  --sea-green: #2b9943;
  --deep-saffron: #f29339;
  --fire-engine-red: #d11a2a;
  --magnolia: #f7f5ff;
  --ultramarine-blue: #605bff;
  --manatee: #9ea1ac;
  --white-smoke: #f5f6fa;
  --pale-lavender: #ddd7ff;
  --glitter: #e7e9ef;
  --silver: #b6b8c3;
  --gainsboro: #d9dbe9;
  --seashell: #fcf3f1;
  --cherry: #dd2c68;
  --lavender-rose: #ff98f1;
  --davy-grey: #50525c;
  /* dark mode */
  --majorelle-blue: #764ce1;
  --bright-lavender: #a988ff;
  --auro-metal-saurus: #717585;
  /* theme colors */
  --secondary-text: #9fa0ac;
  --white: #fff;
  --blue-accent: #3260ab;
  --orange-gradient: linear-gradient(269.79deg, #4890E2 0.18%, #F48C5F 65.09%, #F3A251 96.05%);
  --cta-blue: hsla(218, 80%, 46%, 1);
  --cta-red: rgba(244, 82, 82, 1);
  --cta-orange: rgba(242, 147, 57, 1);
  --selected-blue: #dcecff;
  --tag-blue: hsla(206, 100%, 97%, 1);
  --text-light-grey: hsla(227, 8%, 65%, 1);
  --line: hsla(218, 15%, 85%, 1);
  --hover-grey: hsla(210, 20%, 98%, 1);
  --background: rgba(244, 244, 244, 1);
  --text-grey: hsla(221, 13%, 46%, 1);
  --black: hsla(232, 30%, 11%, 1);
}

[class=dark-mode] {
  /* theme colors */
  --selected-blue: #ddedff;
  --secondary-text: #9fa0ac;
  --white: rgba(23, 24, 28, 0.8);
  --blue-accent: #3260ab;
  --orange-gradient: linear-gradient(269.79deg, #4890E2 0.18%, #F48C5F 65.09%, #F3A251 96.05%);
  --cta-blue: #77A9FF;
  --cta-red: rgba(244, 82, 82, 1);
  --cta-orange: rgba(242, 147, 57, 1);
  --selected-blue: rgba(220, 236, 255, 1);
  --tag-blue: #17418A;
  --text-light-grey: hsla(227, 8%, 65%, 1);
  --line: #474748;
  --hover-grey: rgba(54, 54, 54, 0.4);
  --background: black;
  --text-grey: hsla(221, 13%, 46%, 1);
  --black: #fff;
}

.main-shadow {
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
}

.MuiSlider-marked {
  color: #fff !important;
}

.MuiSlider-rail, .MuiSlider-track {
  background-color: var(--text-light-grey) !important;
}

.MuiSlider-track {
  background-color: var(--cta-blue) !important;
}

.MuiSlider-rail, .MuiSlider-track {
  border-radius: 2px !important;
  height: 4px !important;
}

.mid-separator {
  background-color: var(--selected-blue);
  border-radius: 100px;
  min-width: 33px;
  min-height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--cta-blue);
  transform: translateY(10px);
}

.MuiAlert-standardError {
  background: rgb(232, 244, 253);
  border: 1px solid var(--cta-red);
  color: var(--white);
  border-radius: 8px;
}

.MuiAlert-standardError .MuiAlert-icon:after {
  background-color: #fbbaba;
  border: 5px solid #fbe3e3;
}

.MuiAlert-standardSuccess {
  background: rgb(232, 244, 253);
  border: 1px solid var(--cta-blue);
  color: var(--white);
  border-radius: 8px;
}

.MuiAlert-standardSuccess .MuiAlert-icon:after {
  background-color: #c1e4c1;
  border: 5px solid #e3f5e3;
}

.MuiAlert-standardInfo {
  background: rgb(232, 244, 253);
  border: 1px solid var(--cta-blue);
  color: var(--white);
  border-radius: 8px;
}

.MuiAlert-standardInfo .MuiAlert-icon:after {
  background-color: var(--selected-blue);
  border: 5px solid var(--tag-blue);
}

.MuiAlert-standardWarning {
  background: rgb(255, 240, 228);
  border: 1px solid rgb(220, 104, 3);
  color: #DC6803 !important;
  border-radius: 8px !important;
}

.MuiAlert-standardWarning .MuiAlert-icon:after {
  content: "";
  background-color: rgb(254, 240, 199);
  border: 5px solid rgb(255, 250, 235);
}

.MuiAlert-standardWarning .MuiAlert-message {
  line-height: 20px;
  font-weight: 400;
}

.MuiAlert-standardWarning .MuiAlert-message .alert-header {
  color: #DC6803;
}

.MuiAlert-icon:after {
  content: "";
  position: absolute;
  width: 30px;
  height: 30px;
  top: -1px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 100%;
  z-index: 1;
}

.MuiAlert-root {
  padding: 9px 16px !important;
  border-radius: 7px !important;
}

.MuiAlert-message {
  position: relative;
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 7px;
}

.MuiAlert-message .alert-header {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  display: block;
}

.MuiAlert-icon {
  position: relative;
  margin-right: 20px !important;
  margin-left: 4px !important;
}

.MuiSvgIcon-root {
  z-index: 10;
  width: 18px !important;
}

.recharts-cartesian-grid {
  z-index: 1;
}

.recharts-area {
  z-index: 10;
}

.pool-rows-container a:last-child .pool-row-wrapper {
  border-radius: 0px 0px 6px 6px;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  background: var(--white-smoke);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Inter", sans-serif;
  overflow-y: scroll !important;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal div[aria-hidden=true] {
  background-color: rgba(0, 0, 0, 0.8) !important;
  animation: fadeInOpacityOnly ease 0.5s;
}

.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.fade-in {
  animation: fadeIn ease 0.5s;
}

html {
  overflow-x: hidden;
}

@media screen and (min-width: 700px) {
  /* class for left-side wrapper across the site */
  .page-left-section {
    min-width: 722px;
  }
  .page-right-section {
    min-width: 368px;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes fadeInOpacityOnly {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@media screen and (max-width: 600px) {
  html {
    min-width: 100vw;
  }
}
.rdrStartEdge, .rdrEndEdge, .rdrInRange {
  background: var(--cta-blue) !important;
}

.rdrDays button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rdrDateRangePickerWrapper {
  border-radius: 6px 6px 0 0;
  overflow: hidden;
}

.rdrDayNumber {
  outline: 0;
  font-weight: 300;
  position: absolute;
  left: 0;
  right: 0;
  top: 0 !important;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rdrDayToday .rdrDayNumber span:after {
  bottom: -2px !important;
}

.tool-tip {
  z-index: 999;
}

/* dark mode styles */
.App.dark-mode .dropdown-wrapper {
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.App.dark-mode .dropdown-wrapper:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  z-index: -1;
}

.App.dark-mode .date-time-container, .App.dark-mode .selector-container {
  background: var(--tag-blue);
  border-radius: 6px;
}

.App.dark-mode .date-time-container .formatted-date-top {
  color: white;
}

.App.dark-mode .date-time-container .formatted-date-bottom {
  color: #9EA8CD;
}

.App.dark-mode .asset-row-wrapper,
.App.dark-mode .lending-pool-activity-wrapper,
.App.dark-mode .explore-wrapper .transaction-review,
.App.dark-mode .pool-request-button,
.App.dark-mode .my-pools-left-graph,
.App.dark-mode .my-pools-right-graph,
.App.dark-mode .slideshow-wrapper,
.App.dark-mode .balance-manager-wrapper,
.App.dark-mode .settings-manager-wrapper,
.App.dark-mode .graph-tabs-wrapper,
.App.dark-mode .pool-requests-container,
.App.dark-mode .widget-wrapper,
.App.dark-mode .mode-switch-container,
.App.dark-mode .pool-filters-search-wrapper,
.App.dark-mode .network-connect-button,
.App.dark-mode .options-menu-wrapper,
.App.dark-mode .network-select-wrapper,
.App.dark-mode .options-button,
.App.dark-mode .my-pools-collect-wrapper {
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.App.dark-mode .my-pools-line-tab-wrapper {
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 0;
}

.App.dark-mode .toggle-wrapper-switch {
  background: #000;
}

.App.dark-mode .toggle-wrapper .toggle-wrapper-switch.checked {
  background: var(--tag-blue);
}

.App.dark-mode .my-pools-wrapper .pool-rows-container {
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-top: 0;
}

.App.dark-mode .asset-row-wrapper .pool-rows-container a .pool-rows-wrapper.selected {
  background: #17418A;
}

.App.dark-mode .settings-manager-tab:not(:last-child):after {
  background: rgba(158, 161, 172, 0.05);
}

.App.dark-mode .graph-tabs-wrapper {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.App.dark-mode .strategy-option-wrapper {
  color: white;
}

.App.dark-mode .pool-row-wrapper.selected {
  background: var(--tag-blue);
}

.App.dark-mode .lending-pool-activity-wrapper,
.App.dark-mode .explore-wrapper .transaction-review,
.App.dark-mode .my-pools-wrapper .transaction-review,
.App.dark-mode .widget-wrapper,
.App.dark-mode .my-pools-left-graph,
.App.dark-mode .my-pools-right-graph,
.App.dark-mode .settings-manager-wrapper,
.App.dark-mode .pool-request-button,
.App.dark-mode .pool-requests-container,
.App.dark-mode .balance-manager-wrapper,
.App.dark-mode .asset-row-wrapper,
.App.dark-mode .my-pools-wrapper .pool-rows-container,
.App.dark-mode .my-pools-collect-wrapper {
  position: relative;
  background: var(--white);
}

.App.dark-mode .lending-pool-activity-wrapper:after,
.App.dark-mode .explore-wrapper .transaction-review:after,
.App.dark-mode .my-pools-wrapper .transaction-review:after,
.App.dark-mode .widget-wrapper:after,
.App.dark-mode .my-pools-left-graph:after,
.App.dark-mode .my-pools-right-graph:after,
.App.dark-mode .settings-manager-wrapper:after,
.App.dark-mode .pool-request-button:after,
.App.dark-mode .pool-requests-container:after,
.App.dark-mode .balance-manager-wrapper:after,
.App.dark-mode .asset-row-wrapper:after,
.App.dark-mode .my-pools-wrapper .pool-rows-container:after,
.App.dark-mode .my-pools-collect-wrapper:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  z-index: -1;
}

.App.dark-mode .pool-simulation-wrapper {
  background: none;
}

.App.dark-mode .slideshow-wrapper {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.App.dark-mode .ltv-column .pool-row-column-label {
  background: rgba(0, 0, 0, 0);
  font-size: 14px;
}

.App.dark-mode .extra-filters .borrow-slider-filters-wrapper {
  border: 1px solid rgba(255, 255, 255, 0);
}

.App.dark-mode .wallet-connect-button {
  background: rgba(28, 28, 29, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
}

.App.dark-mode .recharts-xAxis line {
  stroke: rgb(64, 67, 70);
}

.App.dark-mode .tvl-wrapper {
  background: rgba(28, 28, 29, 0.3);
  color: white;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.App.dark-mode .lend-ratio-input {
  color: white;
}

.App.dark-mode .widget-wrapper .lend-ratio-selector button.active {
  background: var(--tag-blue);
  color: white;
}

.App.dark-mode .initial-deposit-input {
  color: white;
}

.App.dark-mode .mid-separator {
  background: var(--tag-blue);
}

.App.dark-mode .slider-filter {
  color: white;
}

.App.dark-mode .borrow-date-filter svg {
  color: white;
}

.App.dark-mode .borrow-date-filter .borrow-date-filter-label {
  color: white !important;
}

.App.dark-mode .token-filter-wrapper {
  background: var(--white);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.App.dark-mode .token-filter-token-wrapper {
  color: white;
}

.App.dark-mode .token-filter-select-all {
  background: var(--tag-blue);
}

.App.dark-mode .mode-switch-button.active {
  background: var(--tag-blue);
  color: white;
}

.App.dark-mode .rate-stat-label {
  color: white;
}

.App.dark-mode .rate-stat-value {
  color: var(--text-light-grey);
}

.App.dark-mode .recharts-cartesian-grid-horizontal line {
  stroke: rgba(255, 255, 255, 0.1);
}

.App.dark-mode .settings-manager-tab {
  color: white;
}

.App.dark-mode .settings-manager-tab::after {
  background: #474748;
}

.App.dark-mode .utilization-value {
  fill: white;
}

.App.dark-mode .explore-wrapper .line-tab span {
  color: white;
}

.App.dark-mode .pool-requests-container .MuiAlert-root {
  -webkit-backdrop-filter: none !important;
  backdrop-filter: none !important;
  border: none !important;
}

.App.dark-mode .pool-requests-container .line-tab.active {
  color: white;
}

.App.dark-mode .graph-tabs-wrapper .line-tab.active {
  color: white;
}

.App.dark-mode .pool-request-button {
  background: var(--white);
  color: white;
}

.App.dark-mode .rate-option.active {
  background: var(--tag-blue);
  color: white;
}

.App.dark-mode .rate-input-wrapper span {
  color: var(--text-light-grey) !important;
}

.App.dark-mode .rate-input-wrapper input {
  color: white;
}

.App.dark-mode .recharts-legend-item-text {
  color: var(--manatee) !important;
}

.App.dark-mode .explore-wrapper .pool-rows-container {
  background: none;
}

.App.dark-mode .pool-rows-container .MuiPaper-root {
  border: none !important;
  -webkit-backdrop-filter: none !important;
  backdrop-filter: none !important;
}

.App.dark-mode .line-tabs-wrapper:after {
  background: rgba(255, 255, 255, 0.5);
}

.App.dark-mode .__react_component_tooltip {
  color: white !important;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.App.dark-mode .loading-skeleton {
  background: rgba(243, 243, 255, 0.3);
}

.App.dark-mode .inner-pool-requests-container::-webkit-scrollbar-track {
  background: var(--line);
}

.App.dark-mode .inner-pool-requests-container::-webkit-scrollbar-thumb {
  background: #f9fafb;
  border-radius: 2px;
}

.App.dark-mode .small-widget .widget-description {
  color: var(--text-light-grey);
}

.App.dark-mode .pool-column-header {
  color: var(--text-light-grey);
}

.App.dark-mode .content-text-wrapper p {
  color: var(--text-light-grey);
}

.App.dark-mode .v1-message a {
  color: white;
}

.App.dark-mode .token-filter-token-wrapper .token-filter-token-price {
  color: var(--text-light-grey);
}

.App.dark-mode .token-filter-token-wrapper:hover {
  background: var(--hover-grey);
}

.App.dark-mode .create-pool-page-status-wrapper .MuiSlider-mark,
.App.dark-mode .create-pool-page-status-wrapper .MuiSlider-markActive {
  outline: 3px solid var(--white) !important;
}

.App.dark-mode .create-pool-page-status-wrapper .MuiSlider-markActive {
  background: var(--tag-blue) !important;
  border: none !important;
}

.App.dark-mode .create-pool-page-status-wrapper .MuiSlider-markActive:after {
  border: 2px solid black;
  border-top: none;
  border-right: none;
}

.App.dark-mode .create-pool-page-status-wrapper .MuiSlider-track {
  background: var(--tag-blue) !important;
}

.App.dark-mode .create-pool-page-status-wrapper .MuiSlider-rail {
  background: rgb(212, 216, 223) !important;
  opacity: 1;
}

.App.dark-mode .token-filter-search, .App.dark-mode .no-tokens-found-message {
  color: white;
}

.App.dark-mode .token-filter-wrapper svg {
  stroke: white;
}

.App.dark-mode .intro-back-button {
  color: white;
}

.App.dark-mode .secondary-action-button-container:hover:not(:disabled) {
  background: var(--hover-grey) !important;
}

.App.dark-mode .simulation-loader > span span {
  border: thick solid white !important;
}

.App.dark-mode .token-filter-select-all {
  color: white;
}

.App.dark-mode .pool-rows-header-container:after {
  background: rgb(71, 71, 72);
}

.App.dark-mode .action-button-container:disabled,
body.dark-mode .action-button-container:disabled {
  color: var(--manatee);
}

.App.dark-mode .options-menu-wrapper,
.App.dark-mode .network-select-wrapper {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.App.dark-mode .options-menu-option {
  color: white;
}

.App.dark-mode .options-menu-option svg {
  color: white;
}

.App.dark-mode .token-filter-wrapper.single,
.App.dark-mode .extra-filters .borrow-slider-filters-wrapper.active {
  background: rgba(28, 28, 29, 0.3);
  color: white;
}

.App.dark-mode .token-filter-wrapper.single .token-filter-count {
  background: white;
  color: black;
}

.App.dark-mode .extra-filters .borrow-slider-filters-wrapper.active .filter-count.active {
  background: white;
  color: black;
}

.App.dark-mode .borrow-date-filter.active {
  background: rgba(28, 28, 29, 0.3);
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.App.dark-mode .pool-stats-button {
  background: hsl(218, 80%, 46%);
}

.App.dark-mode .rollover-row {
  background: none;
}

.App.dark-mode .rollover-row .rollover-text {
  color: white;
}

.App.dark-mode .rollover-row:before {
  background: var(--line);
}

.App.dark-mode .rollover-row:hover {
  background: var(--hover-grey);
}

.App.dark-mode .rollover-row.active {
  background: var(--tag-blue);
}

.App.dark-mode .rollover-row.active .rollover-text {
  color: white;
}

#root.dark-mode .MuiAlert-standardInfo,
body.dark-mode .MuiAlert-standardInfo {
  background: rgba(23, 65, 138, 0.2);
  border: 1px solid hsla(218, 80%, 46%, 0.3);
  color: var(--white);
  border-radius: 8px;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
}

#root.dark-mode .MuiAlert-standardInfo .MuiAlert-icon:after {
  border: 5px solid rgba(240, 248, 255, 0.4);
  background: rgb(53, 78, 181);
}

#root.dark-mode .MuiAlert-standardSuccess {
  background: rgba(9, 148, 90, 0.2);
  border: 1px solid rgba(6, 100, 60, 0.2);
  color: var(--white);
  border-radius: 8px;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
}

#root.dark-mode .MuiAlert-standardSuccess .MuiAlert-icon:after {
  border: 5px solid hsla(140, 100%, 97%, 0.4);
  background: rgb(9, 179, 34);
}

#root.dark-mode .MuiAlert-standardError {
  background: rgba(148, 9, 9, 0.2);
  border: 1px solid rgba(100, 6, 6, 0.2);
  color: var(--white);
  border-radius: 8px;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
}

#root.dark-mode .MuiAlert-standardError .MuiAlert-icon:after {
  border: 5px solid hsla(0, 100%, 97%, 0.4);
  background: rgb(180, 37, 37);
}

#root.dark-mode .MuiAlert-standardWarning {
  background: rgba(167, 80, 0, 0.2);
  border: 1px solid rgba(220, 104, 3, 0.3);
  color: white;
  border-radius: 8px !important;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
}

#root.dark-mode .MuiAlert-standardWarning .MuiAlert-icon:after {
  content: "";
  background-color: #DD6E23;
  border: 5px solid #6F3B0B;
}

#root.dark-mode .MuiAlert-message, #root.dark-mode .alert-header,
body.dark-mode .MuiAlert-message, #root.dark-mode .alert-header {
  color: white;
}

#root.dark-mode .MuiAlert-icon {
  color: white;
}

#root.dark-mode .loader span {
  border: thick solid white !important;
}

#root.dark-mode .set-rollover-input-wrapper {
  background: black;
  color: white;
}

#root.dark-mode .set-rollover-input-wrapper > .potential-rollover:hover {
  background: black;
}

#root.dark-mode .potential-rollover {
  color: white;
}

#root.dark-mode .potential-rollover:hover {
  background: var(--hover-grey);
}

#root.dark-mode .potential-rollover-header-wrapper span {
  color: var(--text-light-grey);
}

#root.dark-mode .update-fee-rate-slider-input {
  color: white;
}

#root.dark-mode .ltv-column .loading-skeleton {
  min-width: 60px;
}

#root.dark-mode .intro-next-button {
  color: white;
}

#root.dark-mode .disclaimer-banner-wrapper {
  background: var(--white);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.05);
}

#root.dark-mode .disclaimer-banner-wrapper .banner-button-wrapper button:last-child {
  color: white;
}

#root.dark-mode .disclaimer-banner-wrapper .banner-button-wrapper button:last-child:hover {
  background: rgb(20, 90, 210) !important;
}

#root.dark-mode .disclaimer-banner-wrapper .disclaimer-header {
  color: white;
}

#root.dark-mode .disclaimer-banner-wrapper .banner-message {
  color: var(--text-light-grey);
}

body.dark-mode .token-modal-container input {
  background: var(--white);
  border: 1px solid rgba(255, 255, 255, 0.05);
  color: white;
}

body.dark-mode .modal .token-categories-wrapper .line-tabs-underline {
  background: var(--tag-blue) !important;
}

body.dark-mode .modal .token-categories-wrapper .line-tab.active {
  color: white !important;
}

body.dark-mode .modal .alert-box-container {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.05);
  outline: none;
  color: white;
}

body.dark-mode .modal .alert-box-container .alert-box-header, body.dark-mode .modal .alert-box-container .alert-box-button {
  color: white;
}

body.dark-mode .modal .disclaimer-wrapper {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.05);
}

body.dark-mode .modal .disclaimer-wrapper .disclaimer-header {
  color: white;
}

body.dark-mode .modal .disclaimer-wrapper ul {
  background: var(--hover-grey);
  color: var(--text-light-grey);
}

body.dark-mode .modal .pool-stats-wrapper,
body.dark-mode .modal .reactions-wrapper {
  border: 1px solid rgba(255, 255, 255, 0.05);
}

body.dark-mode .modal .calendar-button-wrapper {
  background: var(--white);
}

body.dark-mode .modal .rdrDay span {
  color: var(--text-light-grey);
}

body.dark-mode .modal .rdrDateInput {
  background: var(--hover-grey);
}

body.dark-mode .modal .rdrInRange + .rdrDayNumber {
  color: red !important;
}

body.dark-mode .modal .rdrDateDisplayWrapper {
  background: var(--white);
}

body.dark-mode .modal .rdrDefinedRangesWrapper {
  border-right: 1px solid var(--line);
}

body.dark-mode .modal .rdrDateRangePickerWrapper {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

body.dark-mode .modal .rdrCalendarWrapper {
  background: var(--white) !important;
  color: white;
}

body.dark-mode .modal .rdrStaticRange {
  background: var(--white) !important;
  color: white;
  border-bottom: 1px solid var(--line);
}

body.dark-mode .modal .rdrStaticRange:hover {
  color: black;
}

body.dark-mode .modal .rdrDefinedRangesWrapper {
  background: var(--white) !important;
  color: white;
}

body.dark-mode .modal .token-modal-container {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

body.dark-mode .modal .token-modal-header {
  color: white;
}

body.dark-mode .modal .tokens-container .token-container {
  background: none;
}

body.dark-mode .modal .tokens-container .token-container:hover {
  background: var(--hover-grey);
}

body.dark-mode .modal .tokens-container .token-info span {
  color: white;
}

body.dark-mode .modal .reactions-wrapper .reaction-option {
  background: #16171B;
  border: 1px solid #212121;
}

body.dark-mode .modal .reactions-wrapper .reaction-option svg {
  color: white;
}

body.dark-mode .modal .reactions-wrapper .reaction-option.selected {
  background: var(--tag-blue) !important;
}

body.dark-mode .modal .reactions-wrapper .reaction-option.selected .reaction-option-text {
  color: white;
}

body.dark-mode .featured-manager-wrapper .potential-rollover {
  cursor: pointer;
  background: black;
}
body.dark-mode .featured-manager-wrapper .potential-rollover span {
  color: white;
}/*# sourceMappingURL=index.css.map */