.featured-manager-wrapper {
  background: var(--white);
  padding: 22px;
  width: 400px;
  border-radius: 6px;
  outline: none;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}
.featured-manager-wrapper .transaction-review {
  padding: 0;
  box-shadow: none;
  background: none;
}
.featured-manager-wrapper .featured-manager-header {
  margin-bottom: 16px;
}
.featured-manager-wrapper .featured-manager-header span {
  display: block;
  width: 100%;
}
.featured-manager-wrapper .featured-manager-header .header-title {
  font-size: 25px;
  color: var(--black);
  margin-bottom: 11px;
}
.featured-manager-wrapper .featured-manager-header .header-description {
  font-size: 14px;
  color: var(--text-grey);
}
.featured-manager-wrapper .potential-rollover-header-wrapper span:nth-child(2), .featured-manager-wrapper .potential-rollover-header-wrapper span:nth-child(3) {
  transform: translateX(2px);
}
.featured-manager-wrapper .potential-rollover-header-wrapper span:last-child {
  transform: translateX(0px);
}
.featured-manager-wrapper .potential-rollover {
  background: var(--white-smoke);
  border-radius: 6px;
  padding-right: 14px;
}
.featured-manager-wrapper .potential-rollover span {
  max-width: 80px;
}
.featured-manager-wrapper .existing-submission-message {
  margin-top: 16px;
}/*# sourceMappingURL=SubmitFeaturedPool.css.map */