.private-borrowers-wrapper {
  margin-bottom: 18px;
}

.private-borrowers-wrapper .info-title-container {
  margin: 0; 
}

.private-borrowers-input-wrapper {
  width: 99%;
  background: var(--background);
  border-radius: 7px;
  padding: 7px 1em;
  min-height: 40px;
  display: flex;
  flex-wrap: wrap;
  cursor: text;
  max-height: 150px;
  font-family: "Inter", sans-serif;
  overflow-y: auto;
}

.private-borrowers-input {
  border-radius: 10px;
  width: 100%;
  border: none;
  background: none;
  height: 30px;
  font-size: 14px;
  color: var(--black);
  outline: none;
  white-space: nowrap;
  padding-left: 0;
  padding-right: 0;
}

.private-borrowers-input::placeholder {
  font-size: 14px;
}

