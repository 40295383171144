.disclaimer-wrapper {
  width: 600px;
  height: auto;
  color: var(--dark-jungle-green);
  padding: 16px;
  outline: none;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  border-radius: 6px;
  border: 1px solid var(--pale-lavender);
  box-shadow: 0px 0px 11px 0px #000000;
  position: relative;
}

.disclaimer-wrapper svg {
  position: absolute;
  right: 22px;
  top: 18px;
  cursor: pointer;
  transition: all ease 0.3s;
  color: var(--cta-blue);
}


.disclaimer-wrapper svg:hover {
  transform: rotate(180deg);
}

.disclaimer-header {
  flex: 1;
  font-size: 15px;
  font-weight: 500;
  color: rgba(52, 64, 84, 1);
  margin-bottom: 4px;
  margin-top: 0;
}

.disclaimer-wrapper ul {
  border-radius: 6px;
  font-size: 13px;
  background-color: var(--magnolia);
}

.disclaimer-wrapper .disclaimer-buttons {
  display: flex;
  flex-direction: column;
}
.disclaimer-wrapper .disclaimer-buttons button {
  width: 100%;
  height: 50px;
  margin: 5px 0;
  border-radius: 6px;
  transition: all ease 0.3s;
  cursor: pointer;
  font-weight: bold;
}
.disclaimer-wrapper .disclaimer-buttons .agree-button {
  background: var(--purple-gradient);
  color: var(--white-smoke);
}
.disclaimer-wrapper .disclaimer-buttons .agree-button:hover {
  background: var(--purple-gradient-hover);
}

.disclaimer-wrapper .disclaimer-buttons .decline-button {
  background-color: rgba(0, 0, 0, 0);
  outline: 1px solid var(--pale-lavender);
  color: var(--dark-jungle-green);
}
.disclaimer-wrapper h1 {
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
}
.disclaimer-wrapper h3 {
  transform: translateX(-25px);
}
.disclaimer-wrapper ul {
  overflow-y: auto;
  display: block;
  max-height: 250px;
}
.disclaimer-wrapper ul li {
  margin-bottom: 10px;
}

.disclaimer-banner-wrapper {
  position: fixed;
  bottom: 20px;
  max-width: 1105px;
  height: 180px;
  padding: 16px;
  background-color: var(--magnolia);
  border: 1px solid #ACC8FF;
  box-shadow: 0px 1px 10px rgba(59, 58, 67, 0.08);
  border-radius: 8px;
  display: flex;
  color: var(--dark-jungle-green);
  font-size: 11px;
  z-index: 10;
}

.disclaimer-banner-left {
  width: 200px;
  height: 100%;
}

.disclaimer-banner-left img {
  width: 40px;
  margin: auto;
  transform: translateY(-5px);
}

.disclaimer-banner-right {
  display: flex;
  flex-direction: column;
}


.disclaimer-banner-wrapper b {
  cursor: pointer;
}

.banner-button-wrapper {
  display: flex;
  align-items: flex-start;
}

.banner-button-wrapper button {
  width: 130px;
  height: 36px;
  border-radius: 6px;
  cursor: pointer;
  transition: all ease 0.3s;
  margin: 0 !important;
  font-size: 14px;
}

.banner-button-wrapper button:first-child {
  margin-right: 10px !important;
}

.banner-button-wrapper button:last-child {
  background: var(--cta-blue);
  color: var(--white);
}

.banner-button-wrapper button:last-child:hover {
  background: var(--cta-blue) !important; 
  filter: brightness(80%);
}

.banner-message {
  color: rgba(102, 112, 133, 1);
  font-size: 13px;
  margin-bottom: 16px;
  line-height: 18px;
}

.banner-message b {
  text-decoration: underline;

}

@media screen and (max-width: 1180px) {
  .disclaimer-banner-wrapper {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: auto;
  }
  .banner-button-wrapper {
    width: 100%;
    justify-content: flex-start;
    margin-top: 10px;
    margin-left: 0;
  }
}