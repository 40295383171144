/* All Pages */
.double-row {
  display: flex;
  margin-bottom: 5px;
}

.create-pool-wrapper .info-title {
  margin-bottom: 7px;
}

.term-recommendation-message {
  min-height: 60px;
}

.page-1 .term-recommendation-message {
  margin-top: 17px;
}

.page-2 .term-recommendation-message {
  margin-top: -10px;
}

.page-buttons-wrapper {
  display: flex;
  max-width: 100%;
}

.page-buttons-wrapper .action-button-container span {
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-buttons-wrapper .action-button-container span svg {
  width: 20px;
  margin-left: 5px;
}

.page-buttons-wrapper .back-button-wrapper {
  margin-right: 16px;
}

.page-buttons-wrapper > * {
  flex: 1;
}

/* Page 1 */
.page-1 .inner-token-input-container:first-child {
  margin-bottom: 20px;
}

.page-1 .toggle-wrapper .info-title {
  margin-bottom: 0;
}

.page-1 .toggle-wrapper {
  margin-bottom: 20px;
}

.page-1 .request-mode-wrapper {
  margin-bottom: 16px;
}

.page-1 .calendar-button {
  height: 40px;
  width: 100%;
  background: var(--selected-blue);
  color: var(--cta-blue);
  border-radius: 6px;
  cursor: pointer;
}

.page-1 .MuiPaper-root {
  margin-bottom: 17px;
}

.page-1 .selector-container {
  min-width: 100% !important;
  border-radius: 7px;
}

.page-1 .double-row {
  max-width: 100%;
}

.page-1 .double-row .info-section-container {
  width: 50%;
}

.page-1 .double-row .info-section-container:first-child {
  margin-right: 10px;
}

.page-1 .bottom-row {
  margin-bottom: 18px;
}

.page-1 .double-row .info-title-container {
  margin-top: 4px;
}

.page-1 .MuiSlider-thumb {
  color: var(--cta-blue);
}

.page-1 .MuiSlider-mark {
  height: 2px;
  width: 2px;
  transform: translateY(1px);
}

.page-1 .MuiSlider-markLabel {
  font-size: 10px;
  color: var(--text-light-grey);
}

.page-1 .lend-ratio-hint {
  font-size: 10px;
  color: var(--text-grey);
  opacity: 0.8;
}

.lend-ratio-selector {
  width: 97%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
}
.lend-ratio-selector .lend-ratio-slider.disabled .MuiSlider-thumb, .lend-ratio-selector .lend-ratio-slider.disabled .MuiSlider-track {
  color: var(--text-light-grey) !important;
  background-color: var(--text-light-grey) !important;
}

.lend-ratio-option {
  width: 33%;
  height: 75%;
  border-radius: 7px;
  border: none;
  color: var(--secondary-text);
  transition: all ease 0.3s;
  background: var(--background);
  font-weight: 500;
  font-family: "Inter", sans-serif;
}

.lend-ratio-option:not(:last-child) {
  margin-right: 10px;
}

.lend-ratio-selector input {
  width: 100px;
  height: 35px;
  border-radius: 7px;
  border: none;
  color: var(--secondary-text);
  transition: all ease 0.3s;
  background: var(--background);
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  outline: none;
}

.lend-ratio-selector button {
  flex: 2;
  cursor: pointer;
}

.lend-ratio-selector button.active {
  background: var(--selected-blue);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  height: 75%;
  border-radius: 4px;
  border: none;
  color: var(--cta-blue);
}

.lend-ratio-selector button:hover {
  filter: brightness(90%);
}

.lend-ratio-input-container {
  margin-bottom: 8px;
}

.lend-ratio-input-container input {
  flex: 1;
  padding-left: 1em;
  max-width: 25%;
  border-radius: 7px;
  height: 40px;
  border: none;
  background-color: var(--background);
}

.selector-container {
  justify-content: flex-start;
  height: 40px;
}

.initial-deposit-wrapper {
  width: 99%;
  background: var(--background);
  border-radius: 10px;
  padding-left: 1em;
  height: 40px;
  display: flex;
  flex-wrap: wrap;
  cursor: text;
  max-height: 150px;
  overflow-y: hidden;
  position: relative;
}

.initial-deposit-max {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  margin-right: 10px;
  cursor: pointer;
  color: var(--text-light-grey);
}

.initial-deposit-input {
  border-radius: 10px;
  width: 75%;
  border: none;
  background: none;
  height: 40px;
  font-size: 14px;
  color: var(--dark-jungle-green);
  outline: none;
  white-space: nowrap;
  padding-left: 0;
  padding-right: 0;
}

/* Page 2 */
.rate-selection-container {
  display: flex;
  height: 40px;
  border-radius: 7px;
  width: 100%;
  background-color: var(--background);
  justify-content: space-between;
  padding: 4px 4px;
  margin-bottom: 18px;
  align-items: center;
}

.rate-selection-label {
  font-size: 14px;
  line-height: 15px;
  font-weight: 400;
  color: var(--text-grey);
  margin-bottom: 7px;
  display: block;
}

.term-rate-selection-container {
  margin-bottom: 18px;
}

.term-rate-selection-container .MuiSlider-thumb {
  top: 51%;
  color: var(--cta-blue);
}

.term-rate-selection-container .MuiSlider-rail {
  color: var(--background);
  opacity: 0.3;
}

.term-rate-selection-container .MuiSlider-track {
  color: var(--cta-blue);
}

.term-rate-selection-container .MuiSlider-rail, .term-rate-selection-container .MuiSlider-track {
  height: 5px;
  border-radius: 2px;
}

.rate-option {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 96%;
  cursor: pointer;
  border-radius: 7px;
  border: none;
  background: var(--background);
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  transition: all ease 0.3s;
  color: var(--black);
}

.rate-option svg {
  width: 12px;
  height: 12px;
  height: auto;
  margin-left: 5px;
}

.page-2 .MuiAlert-root {
  margin-bottom: 15px;
}

.rate-option:hover {
  filter: brightness(90%);
}

.rate-option:not(:last-child) {
  margin-right: 5px;
}

.rate-option.active {
  background-color: var(--selected-blue);
  color: var(--cta-blue);
  font-family: "Inter", sans-serif;
  font-weight: 500;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  height: 96%;
  border-radius: 4px;
}

.term-rate-label-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  position: relative;
}

.term-rate-label-container span {
  display: flex;
  color: var(--text-grey);
  transform: translateY(5px);
}

.term-rate-label-container svg {
  margin-left: 5px;
  width: 15px;
  color: var(--cta-blue);
}

.rate-input-wrapper {
  display: flex;
  align-items: center;
  background-color: var(--background);
  width: auto;
  border-radius: 5px;
}

.rate-input-wrapper span {
  transform: translateX(-10px);
  font-size: 12px;
  font-family: "Inter", sans-serif;
  color: black;
}

.term-rate-label-container input {
  width: 45px;
  height: 35px;
  text-align: center;
  margin-right: 3px;
  border: none;
  font-size: 12px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  background: none;
  outline: none;
  right: 0;
  top: -8px;
}

.page-2 .MuiSlider-root {
  width: 98%;
  transform: translateX(5px);
}

/* Page 3 */
.page-3 .transaction-review {
  box-shadow: none;
  background: none;
  padding: 0;
  margin: 0;
  margin-bottom: 18px;
}

.page-3 .transaction-review .transaction-info {
  width: 100%;
  margin-bottom: 0 !important;
}

.page-3 .MuiAlert-root {
  margin-top: 19px;
  margin-bottom: 5px;
}/*# sourceMappingURL=Page.css.map */