.global-message-wrapper {
  margin-bottom: 20px;
}

.global-message-wrapper .MuiAlert-icon {
  display: none;
}

.global-message-wrapper span {
  display: flex;
  align-items: center;
}

.global-message-wrapper span svg {
  margin-right: 10px;
  transform: translateY(-1px);
}

.global-message-wrapper .MuiPaper-root {
  display: flex;
  align-items: center;
  border-radius: 7px !important;
  background: rgba(238, 238, 238, 0.5);
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
  border: none;
  color: white;
  font-weight: 600;
  font-size: 14px;
  min-height: 54px;
  font-family: "Sora", sans-serif;
  border: 1px solid rgba(255, 255, 255, 0.3)
}