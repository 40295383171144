.explore-wrapper {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
}

.explore-wrapper .line-tab {
  color: var(--white);
}

.explore-wrapper .line-tabs-wrapper::after {
  opacity: 0.5;
}

.explore-wrapper .line-tab::after {
  background: var(--white);
}

.explore-wrapper .explore-left-section {
  flex: 1;
  margin-right: 15px;
}

.explore-wrapper .explore-right-section {
  flex: 1;
  transition: all ease 0.3s;
  position: -webkit-sticky;
  position: sticky;
  top: 25px;
  height: 500px;
}

.protocol-intro-wrapper {
  background: linear-gradient(
    rgba(0, 0, 0, 0.2), 
    rgba(0, 0, 0, 0.2)
  ),
  url("../../img/gradients/gradient5.jpg");
  box-shadow: 0px 1px 10px rgba(59, 58, 67, 0.2);
  font-size: 14px;
  background-size: cover;
  background-position: 10%;
  border-radius: 6px;
  padding: 29px 23px;
  color: var(--almost-white);
  font-family: "Nunito", sans-serif;
}
.protocol-intro-wrapper h1 {
  font-size: 18px;
  margin-bottom: 15px;
  margin-top: 0;
}
.protocol-intro-wrapper p {
  margin-bottom: 0;
  font-size: 14px;
}

.protocol-intro-wrapper a {
  font-weight: bold;
  text-decoration: underline;
}

.explore-wrapper .tab-container {
  background: none;
  width: 250px;
  margin-bottom: 2em;
}

.explore-asset-rows .no-pools-alert{
  display: flex;
  align-items: center;
  background: rgba(238, 238, 238, 0.5);
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
  border: none;
  color: white;
  font-weight: 600;
  font-size: 14px;
  min-height: 54px;
  font-family: "Sora", sans-serif;
}

.no-pools-alert-message-wrapper {
  display: flex;
  align-items: center;
}

.no-pools-alert-message-wrapper svg {
  margin-right: 10px;
}

.explore-asset-rows .no-pools-alert .MuiAlert-icon {
  display: none;
}

.explore-asset-rows .no-pools-alert .loader {
  fill: var(--cta-blue);
}

.explore-reset-featured {
  color: var(--white);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 1em;
}

.explore-reset-featured svg {
  margin-bottom: 5px;
}

.explore-reset-featured span, .explore-reset-featured svg {
  cursor: pointer;
}

.explore-mid-section {
  position: relative;
  margin-top: 2em;
}

.filter-wrapper {
  width: 100%;
  margin-bottom: 10px;
}

.pool-cards-wrapper {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.MuiAlert-message {
  position: relative;
  width: 100%;
}

.MuiAlert-message .loader {
  position: absolute !important;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.pool-cards-wrapper .no-pools-notice {
  position: absolute;
  display: flex;
  width: 100%;
  height: 60%;
  justify-content: center;
  align-items: center;
}

.explore-right-section.modal {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
  background: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
  overflow-y: auto;
}

@media screen and (max-width: 1180px) {
  .explore-left-section {
    max-width: 100% !important;
    margin-right: 0 !important;
  }
  .lending-pool-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
}