.wrong-network-message {
  color: white;
  display: flex;
  min-height: 45px;
  justify-content: space-between;
  padding: 0 1em;
  align-items: center;
  border-radius: 6px;
  margin-bottom: 16px;
  z-index: 10;
  font-size: 16px !important;
  cursor: pointer;
}

.wrong-network-message-right {
  display: flex;
  align-items: center;
}

.wrong-network-message button {
  color: white;
  background: none;
  border-radius: 6px;
  cursor: pointer;
  padding: 5px 10px;
  font-weight: bold;
}/*# sourceMappingURL=WrongNetwork.css.map */