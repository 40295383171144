.pool-request-button {
  width: 100%;
  height: 55px;
  width: 100%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  outline: none;
  padding: 0 16px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 14px;
  position: relative;
  font-family: "Sora", sans-serif;
}
.pool-request-button .pool-request-right-section {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
}
.pool-request-button .pool-request-right-section svg {
  width: 20px;
  height: 20px;
}
.pool-request-button svg {
  margin-right: 10px;
  color: var(--cta-blue);
}/*# sourceMappingURL=PoolRequestButton.css.map */