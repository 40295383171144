.notification-wrapper .MuiPaper-root {
  border-radius: 8px !important;
  color: var(--dark-jungle-green);
  border: none;
  width: 300px;
  height: auto;
  border-radius: 6px;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  position: relative;
  overflow: hidden;
  font-size: 14px;
}

.notification-wrapper .notification-slider {
  height: 5px;
  animation: slide 5s linear;
  position: absolute;
  bottom: 0;
  left: 0;
  background: var(--orange-gradient);
}

.notification-wrapper .notification-close {
  width: 15px;
  height: 15px;
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 1em;
}

.notification-wrapper .notification-link {
  width: 12px;
  height: 12px;
  cursor: pointer;
  position: absolute;
  top: 3px;
  right: 0;
  color: var(--black);
}

.notification-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notification-top span {
  width: 85%;
}

@keyframes slide {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}/*# sourceMappingURL=Notification.css.map */